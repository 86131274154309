import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from 'store/ducks/athlete';
import { Spinner, Card } from 'components/template';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from './Athlete.module.scss';
import { useTranslation } from 'react-i18next';
import TopBar from './TopBar/TopBar';
import Overview from './Overview/Overview';
import Evaluations from './Evaluations/Evaluations';
import Performance from './Performance/Performance';
import Career from './Career/Career';
import Matches from './Matches/Matches';
import DeletableNotes from './DeletableNotes';
import ReportsPRO from './ReportsPRO/ReportsPRO';
import { IoWarning, IoClose } from "react-icons/io5";

function Athlete({ history }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const athlete = useSelector(state => state.athlete.athleteData);
  const user = useSelector(state => state.user.data);
  const { id } = useParams();
  const athleteId = id;
  const baseURL = `/atletas/${athleteId}`;
  const [isAthleteUpdated, setIsAthleteUpdated] = useState(false);
  const [showIsRerited, setShowIsRerited] = useState(true);

  if (!(user.plan.busca_de_atletas || user.plan.mercado_de_transferencias || user.plan.analise_de_mercado)) {
    localStorage.setItem('rdf_excededPlanLimit', 'true');
    window.location.href = '/home';
  }

  useEffect(() => {
    dispatch(Creators.getAthleteData(athleteId));
    setIsAthleteUpdated(false);
    return () => {
      dispatch(Creators.reset());
    };
  }, [dispatch, athleteId, isAthleteUpdated]);

  const items = [
    {
      label: t('club.box24'),
      link: `${baseURL}`,
      component: <Overview athleteId={athleteId} baseURL={baseURL} setIsAthleteUpdated={setIsAthleteUpdated} />,
    },
    {
      label: t('club.box25'),
      link: `${baseURL}/anotacoes`,
      // amount: athlete?.data?.evaluationsAmount ?? '',
      component: <DeletableNotes athleteId={athleteId} calcHeight={true} inputPosition={'bottom'} />,
    },
    {
      notImplemented: false,
      label: t('overview.text2'),
      link: `${baseURL}/carreira`,
      amount: athlete?.data?.careerAmount ?? '',
      component: <Career athleteId={athleteId} />,
    },
    {
      notImplemented: false,
      label: t('overview.text20'),
      link: `${baseURL}/desempenho`,
      amount: athlete.data.performanceAmount,
      component: <Performance athleteId={athleteId} baseURL={baseURL} />,
    },

    {
      label: t('match.text3'),
      link: `${baseURL}/jogos`,
      amount: athlete?.data?.matchesAmount ?? '',
      component: <Matches athleteId={athleteId} baseURL={baseURL} />,
    },
  ];

  if (user.plan?.evaluations_limit !== 0) {
    items.push({
      label: t('club.box26'),
      link: `${baseURL}/avaliacoes`,
      amount: athlete?.data?.evaluationsAmount ?? '',
      component: <Evaluations athleteId={athleteId} baseURL={baseURL} />,
    });
  }

  items.push({
    label: t('report_pro.title'),
    link: `${baseURL}/relatorios-pro`,
    amount: '',
    component: <ReportsPRO athleteId={athleteId} baseURL={baseURL} athlete={athlete} user={user} />,
  });

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  let content = null;
  if (athlete.loading) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (athlete.error) {
    content = (
      <Card className={styles.errorContainer}>
        <h2>{t(athlete.error)}</h2>
      </Card>
    );
  } else {
    content = (
      <>
        <TopBar athlete={athlete.data} setReportAthletes={() => { }} />
        <div className={styles.content}>
          <div className={styles.navigationDiv}>
            <div className={styles.navigationBar}>
              <NavigationBar items={items} />
            </div>
          </div>
          <Switch>
            {routes}
            <Redirect to={baseURL} />
          </Switch>
          {athlete.data.is_retired && showIsRerited && (
            <div className={styles.is_retired}>
              <IoWarning />
              {t('athlete_card.error.is_retired')}
              <IoClose  
                className={styles.close_is_retired}
                onClick={() => setShowIsRerited(false)} 
              />
            </div>
          )}          
        </div>
      </>
    );
  }

  return <div className={styles.container}>{content}</div>;
}

Athlete.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(Athlete);
