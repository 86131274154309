import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './StepOne.module.scss';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import { FaSearch } from 'react-icons/fa';
import { AiOutlineCheck } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { Table, Input, Spinner, Button } from 'components/template';
import { formatDate } from 'shared/utility';

export default function StepOne({ adsData, setAdsData, loading, athletes }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const [inputValue, setInputValue] = useState('');

  const filteredAthletes = athletes?.filter(item => {
    return item.fullname?.toLowerCase().includes(inputValue);
  });

  const userIsAgency = user?.organization?.id?.type === 'agency';

  const tableData =
    athletes &&
    filteredAthletes.map(item => {
      const athlete = (
        <div
          style={{
            maxWidth: '250px',
            overflow: 'hidden',
          }}
        >
          <AthleteRow name={item.fullname} nickname={item.nickname} img={item.photo} id={item.id} />
        </div>
      );
      const age = item.age;
      const position = t(`positionOptionsRaw.${item.position}`);
      const nac = '';
      const contract = formatDate(item.contract_info.current_contract_end);
      const select =
        item.id === adsData.athlete ? (
          <Button
            disabled={adsData.isEditing.edit}
            className={styles.stepOneSelectedButton}
            onClick={() => {
              setAdsData({
                ...adsData,
                athlete: '',
              });
            }}
          >
            {t('transferMarket.selected')}
            <AiOutlineCheck />
          </Button>
        ) : (
          <Button
            disabled={adsData.isEditing.edit}
            className={styles.stepOneSelectButton}
            onClick={() => {
              setAdsData({
                ...adsData,
                athlete: item.id,
              });
            }}
          >
            {t('transferMarket.select')}
          </Button>
        );

      if (userIsAgency) {
        return [athlete, age, position, nac, select];
      } else {
        return [athlete, age, position, nac, contract, select];
      }

    });

  let content = '';

  if (loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (athletes) {
    content = (
      <Table
        flat
        theads={userIsAgency
          ? [t('table.atleta'), t('table.idade'), t('table.posicao'), t('table.nasc'), '']
          : [t('table.atleta'), t('table.idade'), t('table.posicao'), t('table.nasc'), t('table.contrato'), '']
        }
        className={styles.stepOneTable}
        data={tableData}
      />
    );
  }

  return (
    <div className={styles.stepOneBody}>
      <div className={styles.stepOneText}>
        <span>{t('rest.box24')}</span>
        {userIsAgency && (<span>{t('rest.box24other')}</span>)}
      </div>
      <div className={styles.stepOneInput}>
        <Input
          rounded
          name="name"
          placeholder={t('proj.text24')}
          icon={<FaSearch />}
          iconPosition="right"
          onChange={e => {
            setInputValue(e.target.value.toLowerCase());
          }}
        />
      </div>
      <div className={styles.stepOneTableContainer}>{content}</div>
    </div>
  );
}
