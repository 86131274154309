export const TRANSLATIONS_ESP = {
  pagina_inicial: {
    homepage_1: 'Últimas movimentaciones',
    homepage_2: 'Informaciones del sistema',
    homepage_3: 'Ventanas Abiertas',
    homepage_4: 'Últimos visualizados',
    homepage_5: 'Ver más',
    homepage_5_2: 'Ver más movimentaciones',
    homepage_6: 'Ver todas las ventanas',
    homepage_7: 'Todo',
    homepage_8: 'Registros en el BID',
    homepage_9: 'Actividades',
    homepage_10: 'Información',
    homepage_11: 'Actualizado en',
    homepage_15: 'Ventanas de transferencias',
    homepage_16: 'Mi Feed',
    homepage_17: 'Tu feed con todo lo más importante que ha sucedido desde tu última conexión.',
    homepage_18: 'No hay ventanas abiertas en este momento.',
    homepage_19: 'Ver menos',
    homepage_20: 'Negociaciones',
    open: 'Abierto',
    monitored: 'Monitoreado',
    selectToMonitor: 'Seleccione Windows para monitorear',
    youDontMonitor: 'Aún no supervisas las ventanas de transferencia'
  },

  buttons: {
    excluir: 'Eliminar',
    cancel: 'Cancelar',
    save: 'Guardar',
    edit: 'Editar',
  },

  nav_drawer: {
    meuPerfil: 'Mi Perfil',
    organizacao: 'Organización',
    verPerfilMeuClube: 'Ver perfil de mi club',
    verPerfilMinhaAgencia: 'Ver perfil de mi agencia',
    feed: 'Mi Feed',
    buscaAtletas: 'Búsqueda de Atletas',
    clubes: 'Clubes',
    meuClube: 'Mi Club',
    competicoesBase: 'Competiciones',
    analiseMercado: 'Análisis de Mercado',
    mercadoTransferencias: 'Mercado de Transferencias',
    redeProfissionais: 'Red de Profesionales',
    agencias: 'Agencias',
    comprar: 'COMPRA',
    dashboard: 'Dashboard',
  },

  athlete_card: {
    without_agency: 'No informado',
    idade: ' años',
    meses: 'meses y',
    days: 'días',
    text1: 'Contratos en la CBF',
    text2: 'Fecha del BID',
    text3: 'Inicio',
    text4: 'Fin',
    text5: 'Estado',
    text6: 'Valor(M €)',
    text7: 'Total movido:',
    text8: 'Tipo de Negociación: Todos',
    text9: 'Estado del Anuncio: Todos',
    edit_pic: 'Editar Foto',
    nickname: 'Apodo:',
    fullname: 'Nombre Completo:',
    nation: 'Nacionalidad:',
    birth: 'Nacimiento:',
    height: 'Altura:',
    weight: 'Peso:',
    pos: 'Posición:',
    foot: 'Pie preferido:',
    edit_athl: 'Editar Atleta',
    add_athl: 'Agregar Atleta',
    add_athl_msg1: 'El atleta agregado solo será visible para su organización!',
    add_athl_msg2: 'Tenga la seguridad de utilizarlo en Análisis de Mercado.',
    filter1: 'Club: Todos',
    anotacoes: 'anotaciones',
    buyValuation: 'Comprar Valuation',
    zero_cost: 'Costo cero',
    selectTeam: 'Seleccionar Club',
    error: {
      nationalities: '¡Complete al menos una nacionalidad!',
      name: 'El nombre ingresado no debe contener caracteres no válidos.',
      birthdate: 'El deportista debe tener al menos 7 años para estar registrado.',
      is_retired: 'Este atleta ya no está activo en Footlink.',
    },
  },

  athlete_list: {
    box1: 'Jugador',
    box2: 'Club',
    curret_club: 'Club en este momento',
    box3: 'Edad',
    box4: 'Posición',
    box5: 'Nac.',
    box6: 'Fin del Contrato',
    exist: 'Existe ',
    existplural: 'Existen ',
    founded: 'Encontramos ',
    tip: 'Generar informe',
    text1: ' atleta',
    text1plural: ' atletas',
    text2: ' atleta con los criterios de su búsqueda',
    text2plural: ' atletas con los criterios de su búsqueda',
    noAthletes: 'Ningún atleta fue encontrado con los criterios de su búsqueda.',
    noAthletesReport: 'Debe seleccionar al menos un atleta para generar el informe.',
    ageInComp: 'Edad en la competición', 
  },

  month: {
    // Janeiro,
    // Fevereiro,
    // Março,
    // Abril,
    // Maio,
    // Junho,
    // Julho: "",
    // Agosto: "",
    // Setembro: "",
    // Outubro: "",
    // Novembro: "",
    // Dezembro: "Diciembre",
  },

  contact_card: {
    type: 'Tipo',
    category: 'Categoría',
    role: 'Cargo',
    phone: 'Teléfono',
    error: 'Hubo un problema, por favor revisa tus datos.',
  },

  table: {
    origin: 'Origen',
    destiny: 'Destino',
    age_moment: 'Edad en el momento',
    price: 'Valor',
    text: 'Ver evaluación',
    text2: 'Ver',
    data: 'Fecha',
    analist: 'Analista',
    clube: 'Club en el momento',
    model: 'Modelo de evaluación',
    general: 'Evaluación General',
    criadoEm: 'Creado en',
    atualizadoEm: 'Actualizado en',
    atleta: 'Atleta',
    idade: 'Edad',
    posicao: 'Posición',
    nasc: 'Nac.',
    contrato: 'Contrato hasta',
  },

  dialog_content: {
    list: 'Nueva Lista',
    warn: 'Ya existe una lista con el nombre ingresado',
    create: 'Crear Lista',
    cancel: 'Cancelar',
    noCancel: 'No, cancelar',
    include: 'Incluya al atleta en listas',
    save: 'Guardar',
    proceed: 'Proceder',
    yesProceed: 'Si, proceder',
    new_create: 'Crear nueva lista',
    deleteMessage: '¿Está seguro de que desea eliminar la lista ',
    text1: 'Marcar como agenciado',
    text2: '¿Su agencia representa a este atleta? Márquelo como uno de sus atletas.',
    text3: 'Marcar como mi representado',
    text4: 'Denunciar',
    text5: '¿Este no es el representante de este atleta? Infórmenos para que podamos corregir la información.',
    text6: 'Enviar denuncia',
    text7: 'Desmarcar como agenciado',
    text8: 'No es mi representado',
    text9: '¿Su agencia ',
    text10: ' representa a este atleta? Desmárquelo como uno de sus atletas.',
    text11: 'NO',
    text12: 'Nombre de la nueva lista',
  },

  report: {
    gene_in: 'Generado en ',
    project: 'Proyecto ',
    relat: 'Informe del Atleta',
    gene_for: 'Generado por ',
    pdf: 'Generando PDF...',
    ex_relat: 'Exportar Informe',
    relat_error: 'Se produjo un problema al generar el informe sombra, por favor, inténtelo nuevamente',
    success: '¡Su informe se configuró correctamente!',
    generate: 'En el botón a continuación, podrá imprimir o generar un PDF.',
    export_pdf: ' Exportar a PDF',
    print_out: 'Imprimir/Exportar informe',
    reportData: 'Datos del Informe',
    reportName: 'Nombre del informe',
    position: 'Posición',
    athlete: 'Atleta',
    positions: 'Posiciones',
    appAccessAuthError: 'Está intentando exportar un informe con un usuario distinto al que inició sesión en la WEB, cierre la sesión de su cuenta WEB y vuelva a intentarlo.',
  },

  slider_menu: {
    in: 'Entre',
    de: '(De):',
    ate: '(Hasta):',
    clean: 'Limpiar',
    e: 'y',
    seas: 'Temporada',
    todas: 'Todas',
    season_all: 'Temporada: Todas',
    age_all: 'Edad: Todas',
    value_all: 'Valor: Todas',
    team_all: 'Equipo: Todos',
    competition_all: 'Competición: Todas',
    group_by: 'Agrupar por: ',
    criterion: 'Ningún criterio',
    value_sale: 'Valor de Venta (en €)',
    value_salary: 'Salario anual (en €)',
  },

  filters: {
    add_athlete: 'Agregar atleta',
    active_filters: 'Filtros activos:',
    apply: 'Aplicar',
    key: 'Palabra clave',
    custom_athletes: 'Buscar por deportistas',
    custom_athletes_applied: 'De mi organización',
    custom_athletes_unapplied: 'De Footlink',
    profile: 'Perfil',
    min_sm: 'Mín.',
    mini: 'Mínimo',
    minima: 'Mínima',
    ate: 'Hasta',
    de: 'De',
    select_period: 'Seleccionar periodo',
    pdf: 'PDF',
    video_link: 'Video',
    a: 'a',
    max_sm: 'Máx.',
    max: 'Máximo',
    maxima: 'Máxima',
    games_in: 'partidos en',
    and_max: 'y máximo',
    contract: 'Contrato',
    pos: 'Posición',
    nation: 'Nacionalidad',
    gender: 'Género',
    lists: 'Monitoreando al Atleta',
    lists_default: 'En cualquier lista',
    games: 'Partidos en Temporada',
    gols: 'Goles en Temporada',
    cbf_num: 'Número CBF',
    age: 'Edad',
    birt_year: 'Año de Nacimiento',
    division_ope: 'División en la que actúa',
    country_ope: 'País en el que actúa',
    state_ope: 'Estado en el que actúa',
    clean_filters: 'Limpiar Filtros',
    contract_until: 'Contrato hasta ',
    athl_pro: 'atletas-profesionales',
    athl: 'atletas-amateurs',
    with_contr: 'con-contrato',
    no_contr: 'sin-contrato',
    gen: 'género',
    search: 'Búsqueda de Atletas',
    ath_base: 'Buscar atletas en la base de datos más actualizada de Brasil',
    ath: 'Atletas',
    custom_ath: 'Añadido por la organización',
    multiple: 'Varios',
    order_by: 'Ordenar por',
    byPriority: 'Por Prioridad',
  },

  agencies: {
    birth: 'Nascimento',
    name: 'Nombre',
    searchName: 'Nombre de agencia',
    report_name: 'Nombre del relatório',
    athlete_name: 'Nombre del atleta',
    club: 'Club',
    club_at_moment1: 'Club en este momento',
    club_at_moment2: 'de la evaluación',
    email: 'Correo electrónico',
    agency: 'Agencia',
    agencies: 'Agencias',
    subti: 'Consulte la lista más completa y actualizada de agentes en Brasil',
    origin_countr: 'País de Origen',
    phones: 'Teléfonos',
    lists: 'Listas',
    text1: 'atletas representados por esta organización',
    text2: 'Club Actual',
    text3: 'Contrato Actual',
    text4: 'Club Cedente',
    text5: 'Contrato Cedente',
    text6: 'Aún no hay atletas agregados a esta agencia',
    text7: 'Categoría',
    text8: 'Licencia FIFA',
    text9: 'Por categoria',
    text10: 'Agencias verificadas',
  },

  config: {
    dire: 'Dirección',
    cont: 'Contratar',
    contr: 'contratar',
    mon: 'Monitorear',
    moni: 'monitorar',
    discard: 'descartar',
    toEvaluate: 'Traer para evaluación',
    evaluate: 'trazer-para-avaliacao',
    pri: 'Prioridad',
    hi: 'Alta',
    hig: 'alta',
    dang: 'danger',
    mid: 'Media',
    mi: 'media',
    warn: 'warning',
    lo: 'Baja',
    low: 'baixa',
    inf: 'info',
    model: 'Modelo',
    prior: 'Prior.',
    CONTRATAR: 'Contratar',
    MONITORAR: 'Monitorear',
    OBSERVAR: 'Monitorear',
    DESCARTAR: 'Descartar',
    NEGOCIAR: 'Negociar',
    'TRAZER-PARA-AVALIACAO': 'Traer para evaluación',
    ALTA: 'Alta',
    MEDIA: 'Media',
    BAIXA: 'Baja',
  },

  field: {
    con: 'Concluir',
    edit: 'Editar',
    insert: 'Insertar',
    click: 'Haz clic aquí para insertar tu evaluación',
    click1: 'Haz clic aquí para insertar información.',
    graf: 'Gráfico de Aspectos',
    pare: 'Informe',
    conte: 'Contexto del equipo/liga en el que juega',
    ava: 'Evaluación del Atleta',
    gameData: 'Datos del juego',
    selec: 'Selecciona un modelo de evaluación.',
    asp: 'Aspectos',
    re_ava: 'Evaluaciones Realizadas',
    add_ava: 'Añadir Evaluación',
    text1: 'Este atleta ha sido evaluado en',
    opor: 'oportunidades',
    re_ava2:
      'Consulta las evaluaciones realizadas para este atleta por los analistas de tu organización y por Footure Pro.',
    re_ava1: 'Evaluaciones',
    filter1: 'Analistas: Todas',
    filter2: 'Club actual: Todos',
    filter3: 'Periodo',
  },

  match: {
    text1: 'Próximos Partidos',
    text2: 'Todavía no tenemos registro de los próximos partidos del atleta',
    text3: 'Partidos',
    text4: 'Partidos disputados',
    text5: 'Todavía no tenemos registro de los partidos del atleta',
    comp: 'Nombre de la Competición',
    mand: 'Local',
    pla: 'Marcador',
    guest: 'Visitante',
    obs: 'Observador',
    sum: 'Acta del partido',
    videos: 'Videos',
    text6: '+ VER TODOS LOS VIDEOS',
    availability: 'Disponibilidad',
    text7:
      'Consulta las estadísticas del atleta por partidos. También programa seguir al atleta en sus próximos partidos.',
    text8: 'Estadísticas del Atleta en el partido',
    text9: 'Goles',
    text10: 'Tarjeta Amarilla',
    text11: 'Doble Amarilla',
    text12: 'Tarjeta Roja',
    text13: 'Minutos Jugados',
    text14: 'Titular',
    text15: 'Suplente',
    text16: 'Ingresó',
    text17: 'Salió',
    text18: 'Partidos jugados',
    text19: 'Dos tarjetas amarillas',
    text20: 'Entró al juego',
    text21: 'Dejó el juego',
    text22: 'Asistencias',
    noResults1: 'No se encontraron coincidencias',
    noResults2: 'con estos criterios',
  },

  overview: {
    text1: '+ Ver todas las transferencias',
    text2: 'Carrera',
    text3: 'Total movido:',
    text4: 'Ver Actividad',
    text5: '+ VER TODAS LAS EVALUACIONES',
    text6: '+ VER TODAS LAS NEGOCIACIONES',
    text7: 'Negociaciones',
    text8: 'concluida',
    text9: 'cancelada',
    text10: 'pendiente',
    text11: 'Inicio',
    text12: 'Responsable',
    text13: 'Último Estado',
    text14: 'Contacto',
    text15: 'Tipo',
    text16: 'Estado',
    text17: 'Noticias',
    text18: '+ VER TODAS LAS NOTICIAS',
    text19: '+ Ver rendimiento completo',
    text20: 'Rendimiento',
    text21: 'Cerrar',
    text22: 'Intentar nuevamente',
    text23: 'Rendimiento en las últimas 5 competiciones',
    text24: 'Denunciar',
    text25: 'Últimas 3 transferencias',
    text26: 'Disponibilidad',
    text27: 'Goles en la carrera',
    text28: 'Partidos en la carrera',
    text29: 'Mayor Valor',
    text30: ' en ',
    text31: 'Información del atleta',
  },

  report_pro: {
    title: 'Informes PRO',
    description: 'Consulta los informes generados por profesionales sobre el deportista. Inserta tu informe también.',
    no_reports: 'No hay informes para mostrar',
    image: 'Imagen',
    add_report: 'Agregar informe',
    edit: 'Alterar',
    dialog_h1: 'Datos del informe',
    dialog_title: 'Título',
    dialog_type: 'Tipo de archivo',
    dialog_type_pdf: 'Archivo PDF',
    dialog_type_video: 'Enlace al vídeo',
    file: 'Archivo',
    video: 'Enlace al vídeo',
    select_file: 'Seleccionar archivo',
    access: 'visita{{sufix}}',
    public: 'Público',
    only_agency: 'Sólo mi organización ve',
    when_see: '¿Quién puede ver?',
    when_see_agency: 'Sólo mi organización',
    delete_report: 'Eliminar informe',
    confirm_delete_report: '¿Está seguro de que desea eliminar este informe?',
    filter_type: 'Por tipo',
    filter_period: 'Por periodo',
    filter_origin: 'Por origen',
    filter_myagency: 'Solo insertado por mi',
    button_add: 'Agregar informe PRO',
  },

  myAgency: {
    recomendedDimensionLogo: 'Tamaño de logotipo recomendado: 200x200 px',
    agency_info: 'Información de la agencia',
    athletes_in: 'Atletas en Footlink',
    principal_market: 'Mercado Principal',
    my_athletes: 'Mis atletas',
    my_athletes_desc: 'Gestiona la información de los deportistas de tu agencia',
    athletes_profile: 'Perfil de los atletas',
    represent: 'Representación',
    transfer: 'Transferencias',
    dialog_title: 'Detalles de la agencia',
    edit_agency: 'Editar datos de la agencia',
  },

  myClub: {
    negotiations: {
      negotiations: 'Gestión de mercado',
      subtitle: 'Mantén el control sobre las negociaciones realizadas por tu club.',
      originTransferMarket: 'Origen en el Mercado de Transferencias',
      exit: 'Salidas',
      arrivals: 'Llegadas',
      allPositions: 'Posición: Todas',
      inConversation: 'En conversación',
      discarded: 'Descartados',
      inNegotiation: 'En negociación',
      negotiated: 'Negociados',
      addAthlete: '+ Añadir Atleta',
      list: 'Lista',
      report: 'Relatório',
      model: 'Modelo',
      direc: 'Direcionamento',
      priority: 'Prioridade',
    },
    general: {
      title: 'Visión General',
      contractTitle: 'Contratos a vencer',
      contractSeeMore: 'Ver todos los contratos',
      info: 'Información del club',
      emblem: 'Escudo',
      addImage: 'Añadir imagen',
      clubData: 'Datos del Club',
      stadium: 'Estadio',
      partnersNumber: 'Número de Socios',
      expireIn: 'Venciendo en',
      dialogTitle: 'Editar datos del Club',
    },
    users: {
      title: 'Usuarios',
      subtitle: 'Administra y controla los usuarios que pueden acceder a la cuenta de tu organización',
      remove: 'Remover',
      editPermission: 'Editar permisos',
      cancel: 'Cancelar',
      removeUser: 'Remover Usuario',
      warningMessage: '¿Estás seguro de que deseas remover al usuario',
      errorMessage: 'Ocurrió un error al intentar remover al usuario. Por favor, inténtalo nuevamente',
    },
    professionalCast: {
      pageTitle: 'Mi Plantilla',
      pageSubTitle: 'Administra la información de los atletas de tu club.',
      contractUntil: 'Contrato hasta',
      cedingClub: 'Club Cedente',
      cedingContract: 'Contrato Cedente',
      loanedClub: 'Club de Préstamo',
      loanedContract: 'Contrato de Préstamo',
    },
    profileCast: {
      title: 'Perfil del Elenco',
      pageTitle: 'Perfil del Elenco Profesional',
      pageSubTitle: 'Sigue el perfil de edad del elenco y su tiempo en el campo de juego.',
      male: 'Masculino',
      female: 'Femenino',
      filterBy: 'Filtrar por: ',
      development: 'Desarrollo',
      cutting: 'Pulido',
      performance_peak: 'Pico de Rendimiento',
      sustain: 'Sostenimiento',
      tutor: 'Tutor',
      minutes_played_label: 'Minutos en el Campo',
      age_label: 'Edad',
      info_label: '* Este gráfico está limitado a 40 atletas.',
      athlete_all: 'Atleta: Todos',
    },
    contracts: {
      pageTitle: 'Contratos',
      pageSubTitle: 'Sigue la expiración de los contratos de los atletas de tu elenco.',
      contractGroup_finished: 'Sin contrato',
      contractGroup_danger: 'Menos de {{contractHighRisk}} meses para la expiración',
      contractGroup_warning: 'Entre {{contractHighRisk}} y {{contractWarningRisk}} meses para la expiración',
      contractGroup_ok: 'Más de {{contractWarningRisk}} meses para la expiración',
      contractGroupEmpty_finished: 'Sin contratos vencidos',
      contractGroupEmpty_danger: 'Ningún contrato expira en menos de {{contractHighRisk}} meses',
      contractGroupEmpty_warning: 'Ningún contrato expira entre {{contractHighRisk}} y {{contractWarningRisk}} meses',
      contractGroupEmpty_ok: 'Ningún contrato expira en más de {{contractWarningRisk}} meses',
      contractResumeCount_finished: '{{length}} atletas',
      contractResumeCount_danger: '{{length}} contratos',
      contractResumeCount_warning: '{{length}} contratos',
      contractResumeDesc_finished: 'sin contrato',
      contractResumeDesc_danger: 'con Riesgo Alto',
      contractResumeDesc_warning: 'con Riesgo Medio',
      dialogTitle: 'Editar Alertas de Expiración de Contratos',
      dialogDesc: 'Edita los tiempos de alerta para la expiración de contratos (en meses)',
      riskTitle_danger: 'Riesgo Alto:',
      riskTitle_warning: 'Riesgo Medio:',
      riskTitle_ok: 'Riesgo Bajo:',
      riskDescLessThan: 'Menor que',
      riskDescGreatherThan: 'Mayor que',
      riskDescMonths: 'meses',
      riskDescAnd: 'y',
      tableColumn: {
        athlete: 'Atleta',
        age: 'Edad',
        position: 'Posición',
        nationality: 'Nacionalidad',
        sourceTeam: 'Club Cedente / Contrato Club Cedente',
        contractType: 'Tipo de contrato',
        contractNumber: 'Contrato',
        contractStart: 'Inicio del Contrato',
        contractEnd: 'Fin del Contrato',
      },
    },
  },

  club: {
    text1: 'Plazo del Contrato:',
    text2: 'Prestado:',
    text3: 'Sí',
    text4: 'No',
    text5: 'Sin evaluación',
    text7: 'VALOR ACTUAL DE MERCADO',
    text8: 'MAYOR VALOR DE MERCADO',
    text9: 'no informado',
    text10: 'Editar datos del atleta',
    text11: 'Agencia',
    text12: 'No informada',
    text13: 'Altura',
    text14: 'Peso',
    text15: 'Evaluación Técnica',
    text16: 'Desempeño',
    text17: 'Club/Selección',
    text18: 'Consulta el historial de rendimiento del atleta por temporada, competición, clubes y selecciones.',
    box1: 'No encontramos nada con estos términos.',
    box2: 'Datos del informe:',
    box3: 'Informe generado para el proyecto',
    box4: 'Descripción',
    box5: 'Máximo de 250 caracteres',
    box6: 'Contenido del Informe',
    box7: 'Atletas',
    box8: 'Añadir atleta',
    box9: 'Total Temporada',
    box10: 'Características Generales',
    box11: 'Negociación',
    box12: 'Agente',
    box13: 'Salario',
    box14: 'Inversión',
    box15: 'Evaluación',
    box16: 'Contexto en el que actúa',
    box17: 'Aún no hay evaluaciones para este atleta',
    box18: 'Sin título',
    box19: 'Historial de Carrera del Atleta',
    box20: 'Transferencias',
    box21: 'CLUB ACTUAL',
    box22: 'Hasta',
    box23: 'CLUB CEDENTE',
    box24: 'Visión General',
    box25: 'Anotaciones',
    box26: 'Evaluaciones',
    box27: 'Mis anuncios',
    box28: 'Atletas disponibles',
    box29: 'Mis negociaciones',
    box30: 'Atletas deseados',
    noData: 'No hay atletas para mostrar',
  },

  text: {
    box1: 'Club Préstamo',
    box2: 'Contrato Préstamo',
    box3: 'Contactos',
    box4: 'Este club fue evaluado en ',
    box5: 'Monitoreo',
    box6: 'Noticia',
    box7: 'Añadir noticia',
    box8: 'Competiciones más recientes',
    box9: 'Posición',
    box10: '+ VER TODAS LAS COMPETICIONES',
    box11: 'Fuente',
    box12: 'Fundación',
    box13: 'Sitio Oficial',
    box14: 'Socios',
    box15: 'Dirección',
    box16: 'Código Postal',
    box17: '+ VER TODOS LOS CONTACTOS',
    box18: 'Directivos',
    box19: 'Estadio',
    box20: '+ VER TODAS LAS TRANSFERENCIAS',
    box21: 'plantilla',
    box22: 'Contrato Cedente',
    box23: 'VALOR DE MERCADO',
    box24: 'Opción 1',
    box25: 'Opción 2',
    box26: 'Opción 3',
    box27: 'Período',
    box28: 'Ubicación',
    box29: 'Resultado',
    box30: 'Plantilla',
    box31: 'Prestados',
    box32: 'Amateurs',
    box33: 'Femenino',
    box34: 'Estado',
    box35: 'Ciudad',
    box36: 'País',
    box37: 'Aficionado',
    box38: 'Prestado',
  },

  compe: {
    text1: 'País',
    text2: 'Ámbito',
    text3: 'Actual',
    text4: 'Club competición',
    text5: 'Atletas Inscritos',
    text6: 'Ningún partido encontrado',
    text7: 'Publicado en ',
    text8: 'Acompaña los destaques de la ',
    text9: ' con los informes producidos por Footure PRO.',
    text10: 'Ningún informe encontrado',
    text11: 'Informes',
    text12: 'Clubes',
    text13: 'Goleo',
    text14: 'Ver resumen',
    text15: 'Ver vídeo',
    text16: 'Ningún juego encontrado',
    text17: 'Juegos en la competición',
    text18: 'Cedente',
    text19: 'Inscritos',
    text20: 'Plantilla en la competición',
    text21: 'Organizador',
    text22: 'Región',
    text23: 'Fin',
    text24: 'Competiciones',
    text25: 'Somos la mayor fuente de competiciones de fútbol base y femenino de Brasil.',
    text26: 'Profesional',
    loadMore: 'Cargar más partidos',
    precociousAthletes: 'Atletas Precoces',
    context: {
      R: 'Regional',
      Regional: 'Regional',
      N: 'Nacional',
      Nacional: 'Nacional',
      SA: 'Sulamericano',
      Sulamericano: 'Sudamericano',
    },
    currentSeason: 'Actual temporada',
  },

  organi: {
    box1: 'Actualizar',
    box2: 'Avatar',
    box3: 'Nombre de la organización',
    box4: 'Correo electrónico general',
    box5: 'Teléfono 1',
    box6: 'Teléfono 2',
    box7: 'Dirección principal',
    box8: 'Dirección completa',
    box9: 'Ciudad',
    box10: 'Redes sociales',
    box11: 'Actualizar configuraciones',
    box12: 'El Teléfono 1 debe tener DDI, DDD y número.',
    box13: 'El Teléfono 2 debe tener DDI, DDD y número.',
    box14: 'El Whatsapp debe tener DDI, DDD y número.',
    box15: 'Avatar',
    box16: 'Nombre del estadio',
    box17: 'Nombre popular',
    box18: 'Fecha de fundación',
    box19: 'Número de socios',
    box20: 'Seleccione',
    text1: 'Configuraciones',
    text2: 'Administra tu cuenta y tus preferencias',
    text3: 'Cobro',
    text4: 'Organización',
    text5: 'Eliminar evaluación',
    text6: 'Realizado en',
    text7: 'Monitorea y accede rápidamente cuando lo necesites.',
    text8: 'Eliminar Evaluaciones',
    text9: 'Confirmar',
    text10: '¿Estás seguro de que deseas eliminar las evaluaciones seleccionadas?',
    text11: 'División',
    text12: 'Serie',
    text13: 'Atletas',
    text14: 'Valor de mercado',
    text15: 'Clubes extranjeros',
    text16: 'Busca atletas e información de clubes de Brasil y el mundo',
    text17: 'comenzó a ',
    text18: 'el atleta',
    text19: 'generó un nuevo informe',
    text20: 'En ',
    text21: ' a las ',
    text22: 'Atleta anunciado',
    text23: 'Tipo de negocio',
    text24: 'Valor',
    text25: 'Publicación',
    text26: 'Expiración',
    text27: 'Interesados/Visualizaciones',
    text28: 'Estado',
    text29: 'de',
    text30: 'envió un mensaje sobre el anuncio del atleta',
    text31: 'insertó',
    reportPro: 'el Informe PRO "{{report_name}}" d',
    evaluationSaved: 'Clasificación guardada con éxito',
    desiredAthlete: 'Atleta Deseado',
    inNegotiationsOferreds: 'En negociación / Ofrecido',
  },

  home: {
    support: 'Soporte de contacto',
    box1: 'registró',
    box2: 'del atleta',
    box3: 'en el BID de la CBF',
    box4: 'En: ',
    box5: 'Hasta: ',
    box6: 'Liberar',
    box7: 'Registro para iniciación deportiva',
    box8: 'Rescisión',
    box9: 'Reversión',
    box10: 'Contraseña',
    box11: 'Olvidé mi contraseña',
    box12: 'Permanecer conectado',
    box13: 'Espera',
    box14: 'Iniciar sesión en mi cuenta',
    box15: 'Ingresa tu correo electrónico',
    box16: 'Recuperar contraseña',
    box17: 'Volver',
    box18: 'Hemos enviado un correo electrónico para que generes una nueva contraseña.',
    box19: 'Eliminar Atletas',
    box20: 'Eliminar',
    text1: '¿Estás seguro de que deseas eliminar todos los atletas seleccionados de las listas de monitoreo?',
    text2: 'Por estado',
    text3: ' - Nacional',
    text4: 'Clubes Brasileños',
    text5: 'Todos',
    text6: 'Serie A',
    text7: 'Serie B',
    text8: 'Serie C',
    text9: 'Serie D',
    text10: 'Análisis de Mercado',
    text11: 'Administra y organiza tus procesos de análisis de mercado de manera colaborativa',
    text12: 'Crea tu primer proyecto',
    text13: 'Agregar Proyecto',
    text14: 'La nueva contraseña y la confirmación de contraseña deben ser iguales',
    text15: 'Contraseña actual',
    text16: 'Nueva contraseña',
    text17: 'Confirmar nueva contraseña',
    text18: 'Mi perfil',
    text19: 'Cambiar contraseña',
    text20: 'Administra tu perfil y tus preferencias',
    footlinkClubs: 'Clubes Footlink',
    footlinkClient: 'Cliente Footlink',
  },

  proj: {
    campo: 'Campo',
    linkProjetos: 'Proyectos',
    linkRelatorios: 'Informes',
    linkMonitoramento: 'Monitoreo',
    linkAvaliacoes: 'Evaluaciones',
    linkJanelasTransferencias: 'Ventanas de Transferencias',
    box1: 'Ver Perfil',
    box2: 'Editar Informe',
    box3: 'Historial de Comentarios',
    box4: 'Importar comentarios seleccionados',
    box5: 'Importar',
    box6: 'AGREGADO POR',
    box7: 'INDICADO POR',
    box8: 'el 00/00/000',
    box9: 'Indicado por',
    box10: 'Prueba',
    box11: 'Comentarios',
    box12: 'Ya has realizado',
    box13: 'comentarios dentro del perfil de este atleta.',
    box14: 'Ver comentarios',
    box15: 'Propietario',
    box16: 'creó este proyecto',
    box17: 'No hay historial para mostrar',
    box18: 'Monitoreando',
    box19: 'Se produjo un problema al sincronizar el Workflow, por favor actualiza tu página',
    box20: 'atletas',
    text1: '+ Agregar atleta',
    text2: '+ Agregar lista',
    text3: 'Crea tu primer Equipo Sombra',
    text4: 'Agregar equipo sombra',
    text5: 'Ver equipo sombra',
    text6: 'Equipo Sombra',
    text7: 'Esquema: Todos',
    text8: 'No hay atletas para mostrar',
    text9: 'Ingrese un título',
    text10: 'Seleccione el esquema: ',
    text11: 'Se produjo un problema durante la comunicación con el servidor, por favor inténtalo nuevamente más tarde',
    text12: 'No hay atletas definidos para esta posición',
    text13: 'Por favor, selecciona la posición deseada para el informe',
    text14: 'Seleccionar Atleta',
    text15: 'Etapa',
    text16: 'Atletas en esta posición',
    text17: 'Prueba 1',
    text18: 'Prueba 2',
    text19: 'Prueba 3',
    text20: '...Cargando',
    text21: 'Workflow',
    text22: 'Organiza y personaliza tu proceso de análisis de mercado',
    text23: 'Esquema',
    text24: 'Nombre del Atleta',
    create: 'Crear',
    proj: 'Proyecto',
    pageTitleTimeSombra: 'Equipos Sombra',
    pageDescTimeSombra: 'Crea equipos sombra a partir de los atletas seleccionados en tu proyecto.',
    filtrarPor: 'Filtrar por: ',
    filtarPorPlaceholder: 'Nombre del equipo sombra',
    ultimaAtualizacao: 'Última Actualización: ',
    montarTimeSombra: 'Armar Equipo Sombra',
    montarTimeSombraDesc: 'Elige tu modelo de campo, esquema y color del campo, y arma tu Equipo Sombra.',
    nomeTimeSombra: 'Nombre del Equipo Sombra: ',
    corDoCampo: 'Color del Campo: ',
    corDoCampoVerde: 'Verde',
    corDoCampoEscuro: 'Oscuro',
    corDoCampoBranco: 'Blanco',
    salvarContinuar: 'Guardar y continuar',
    esquema: 'Esquema: ',
    modeloDeCampo: 'Modelo de Campo: ',
    modeloStandard: 'Estándar',
    modeloArea: 'Áreas',
    modeloRunner: 'Corredores',
    editarTimeSombra: 'Editar Equipo Sombra',
    adicionarPosicao: 'Agregar Posición',
    editarPosicao: 'Posición ',
    posicaoPanelTitle: 'Atletas de la posición',
    posicaoInputNome: 'Nombre de la posición',
    adicionarAtleta: 'Agregar Atleta',
    atletaPanelTitle: 'Seleccionar Atleta',
    atletaFiltroNome: 'Nombre del atleta',
    atletaFiltroEtapaTodos: 'Etapa: Todas',
    atletaFiltroPosicaoTodos: 'Posición: Todas',
    atletaCorAmarelo: 'Amarillo',
    atletaCorVerde: 'Verde',
    atletaCorAzul: 'Azul',
    atletaCorVermelho: 'Rojo',
    atletaCorCinza: 'Gris',
    erroSchema: 'Por favor, define el Esquema deseado',
    erroNome: 'Por favor, define el Nombre deseado',
    historyTitle: 'Historial',
    historySubTitle: 'Mantén el control sobre todas las actividades de tu proyecto.',
    userTitle: 'Usuarios',
    userSubTitle: 'Administra el equipo que trabaja en el proyecto.',
    deleteTitle: 'Eliminar Lista',
    deleteMessage:
      'La eliminación de la columna eliminará a todos los atletas de esta y esta acción no se puede deshacer. ¿Estás seguro de que quieres continuar con la eliminación?',
    applyFilters: 'Aplicar',
    linkMercadoTransferencia: 'Mercado de Transferencias',
    subMercadoTransferencia: 'Negocia directamente con los atletas y con mayor transparencia.',
    modalUploadImagem: 'Resolución recomendada',
    indicatedByEditing: ' (Presione para editar)',
    confirmationDeleteModal: '¿Estás seguro de que deseas eliminar todo este proyecto?',
    orderCreatedAtAsc: 'Creación - Más antigua',
    orderCreatedAtDesc: 'Creación - Más reciente',
    orderUpdatedAtAsc: 'Actualización - Más antigua',
    orderUpdatedAtDesc: 'Actualización - Más reciente',
    orderNameAsc: 'Orden alfabetica - A > Z',
    orderNameDesc: 'Orden alfabetica - Z > A',
    removeAthlete: 'Eliminar atleta',
    removeAthleteMessage: '¿Estás seguro de que quieres eliminar al atleta?',
    athleteProjectCount: 'Está presente en {{count}} proyecto',
  },

  user: {
    box1: '¡Correo electrónico enviado con éxito!',
    box2: 'Ingresa un correo electrónico válido',
    box3: 'Buscar por nombre',
    box4: 'Perfil',
    box5: 'Agregar',
    box6: '¿No está en la lista de arriba? Invítalo por correo electrónico.',
    box7: 'Ingresa el correo electrónico de la persona',
    box8: 'Editar Usuarios',
    box9: 'Hace unos minutos',
    box10: 'hora(s) atrás',
    box11: 'día(s) atrás',
    box12: 'Añadido en',
    box13: 'Última actividad',
    box14: 'Colaboradores',
    box15: 'Administrar usuarios',
    box16: 'Creado en',
    box17: 'Última actualización en',
    box18: 'por',
    box19: 'Visualizador',
    box20: 'Administrador',
    text1: 'Editor',
    text2: 'Historial',
    text3: 'Usuarios',
    text4: 'Ningún resultado encontrado',
    text5: 'Nombre del Proyecto',
    text6: 'Agregar Proyecto',
    text7: 'Ningún atleta agregado',
    text8: 'Ver informe',
    text9: 'Atletas en el Informe',
    text10: 'Eliminar Informes',
    text11: 'Eliminar',
    text12: '¿Estás seguro de que deseas eliminar los informes seleccionados?',
    text13: 'Tu contraseña ha sido cambiada. Inicia sesión utilizando la nueva contraseña.',
    text14: 'Ingresa la nueva contraseña',
    text15: 'Confirma la nueva contrasenã',
    text16: 'Masculino',
    text17: 'Amateur',
    text18: 'Abierto',
    text19: 'Cerrado',
    text20: 'Por País',
    text21: 'Modalidad',
    text22: 'Por Modalidad',
    text23: 'Por Estado',
    text24: 'Inicio de Temporada',
    text25: 'Fin de Temporada',
    text26: 'Ventana 1 (Inicio/Fin)',
    text27: 'Ventana 2 (Inicio/Fin)',
    text28: 'Ventana 3 (Inicio/Fin)',
    text29: 'Ventanas',
    text30: 'Mantente informado sobre las ventanas de transferencia en todos los países',
    text31: 'Nombre del Atleta',
    text32: 'Mis anuncios',
    text32sub: 'Maximiza tus oportunidades de negociación de atletas con nuestra solución de gestión de anuncios.',
    text33: 'Agregar Anuncio',
  },

  login: {
    alreadyLoggedText1: 'Tu cuenta Footlink está en uso en otro dispositivo.',
    alreadyLoggedText2: 'Por su seguridad, no permitimos el acceso simultáneo con la misma cuenta.',
    alreadyLoggedText3: '¿Quiere forzar el inicio de sesión y cerrar todas las sesiones abiertas?',
    forceLogin: 'Sí, forzar inicio de sesión',
  },

  app: {
    box1: 'Ver todas las actividades',
    box2: 'Notificaciones y Alertas',
    box3: 'Más notificaciones',
    box4: 'Ver club',
    box5: 'Salir',
    box6: 'Ver todos',
    box7: 'Ingresa el nombre de un atleta o club (al menos 3 caracteres)',
    box8: 'BÚSQUEDA AVANZADA',
    box9: 'Ventanas activas',
    box10: 'Ver todas las ventanas',
    box12: 'PRO',
    box13: 'Página de inicio',
    box14: 'Atletas y Clubes',
    box15: 'Base',
    box16: 'Competiciones',
    box17: 'Copinha 2023',
    box18: 'Análisis de Mercado',
    box19: 'Listado de Agencias',
    box20: 'Configuración del Informe',
    text1: 'Edición',
    text2: 'Vista previa',
    text3: 'Guardando ca mbios...',
    text4: '¡Cambios guardados con éxito!',
    text5: 'de',
    text6: 'por página',
    text7: 'Elementos',
    text8: 'Conversaciones',
    text9: 'Crear anuncio',
  },

  preferentialFoot: {
    null: '',
    Ambos: 'Ambos',
    Direito: 'Derecho',
    Esquerdo: 'Izquierdo',
    Indiferente: 'Indiferente',
    undefined: 'No informado',
  },

  performance: {
    ABAIXO: 'Por debajo del nivel del partido',
    MEDIO: 'En el promedio del nivel del partido',
    ACIMA: 'Por encima del nivel del partido',
    MUITO_ACIMA: 'Muy por encima del nivel del partido',
    null: '',
    undefined: 'No informado',
  },

  positionOptions: {
    null: '',
    Atacante: 'Delantero',
    Ataque: 'Ataque',
    Centroavante: 'Centrodelantero',
    Defesa: 'Defensa',
    Goleiro: 'Portero',
    LateralDireito: 'Lateral derecho',
    LateralEsquerdo: 'Lateral izquierdo',
    Meia: 'Mediocampista',
    MeiaAtacante: 'Mediocampista ofensivo',
    'Meia-atacante': 'Mediocampista ofensivo',
    MeioCampo: 'Mediocampo',
    Volante: 'Volante',
    Zagueiro: 'Defensa central',
    ExtremoEsquerdo: 'Extremo izquierdo',
    ExtremoDireito:'Extremo Derecho',
    'Lateral Direito': 'Lateral Derecho',
    'Lateral Esquerdo': 'Lateral Izquierdo',
    'Meio-campo': 'Medio-Campo',
    'Extremo Esquerdo': 'Extremo Izquierdo',
    'Extremo Direito':'Extremo Derecho',
    Outro: 'Otro',
    undefined: 'No informado',
  },

  positionOptionsRaw: {
    null: '',
    Atacante: 'Delantero',
    Ataque: 'Ataque',
    Centroavante: 'Centrodelantero',
    Defesa: 'Defensa',
    Goleiro: 'Portero',
    'Lateral Direito': 'Lateral derecho',
    'Lateral Esquerdo': 'Lateral izquierdo',
    Meia: 'Mediocampista',
    'Meia-atacante': 'Mediocampista ofensivo',
    'Meio-campo': 'Mediocampo',
    Volante: 'Volante',
    Zagueiro: 'Defensa central',
    undefined: 'No informado',
  },

  newDivisionOptions: {
    1: '1ª División',
    2: '2ª División',
    3: '3ª División',
    4: '4ª División',
    division: 'División',
    UmDivisao: '1ª División',
    DoisDivisao: '2ª División',
    TresDivisao: '3ª División',
    QuatroDivisao: '4ª División',
    SemDivisao: 'Sin División',
  },

  errorMessage: {
    expiredAccount: 'Su cuenta ha caducado, por favor contáctenos para renovar su acceso. Puede encontrar nuestro contacto a través del sitio web footlink.app',
    serviceUnavailable: 'Servicio no disponible. Inténtalo de nuevo más tarde.',
    defaultCredentialsError: 'No hemos podido validar tus credenciales. Inténtalo de nuevo.',
    resourceNotFoundAth: 'Atleta no encontrado.',
    resourceNotFoundClub: 'Club no encontrado.',
    maximumAthleteReached: 'Se alcanzó el número máximo de atletas gestionados.',
    minMax: 'Máx debe ser mayor que Min',
  },

  SystemInfoName: {
    teams: 'Equipos',
    athletes: 'Jugadores',
    market_value: 'Valor de mercado',
    competitions: 'Competiciones',
    transfers: 'Transferencias de jugadores fuera de Brasil',
    bid: 'Contratos - BID de la CBF',
    historical_data_prof: 'Estadísticas de partidos (Profesional)',
    historical_data_base: 'Estadísticas de partidos (Base)',
    match_data: 'Partidos',
    transfer_windows: 'Ventanas de transferencias',
  },

  yesNo: {
    nao: 'No',
    sim: 'Sí',
  },

  reportCheckboxes: {
    generalFeatures: 'Características generales',
    negotiation: 'Negociación',
    images: 'Imágenes',
    evaluations: 'Evaluaciones',
    historic: 'Desempeño',
    career: 'Carrera',
  },

  targetingOptions: {
    Contratar: 'Contratar',
  },

  radioAdvancedSearch: {
    female: 'Femenino',
    male: 'Masculino',
  },

  radioAdvancedSearchCustomAthlete: {
    custom: 'Agregado por mi organización',
    normal: 'Del banco de atletas de Footlink',
  },

  contractOptions: {
    'com-contrato': 'Con contrato',
    'sem-contrato': 'Sin contrato',
  },

  profileOptions: {
    'atletas-profissionais': 'Jugadores profesionales',
    'atletas-amadores': 'Jugadores amateurs',
  },

  negotiationOptions: {
    SALE: 'Venta',
    LOAN: 'Préstamo',
  },

  desiredNegotiationOptions: {
    SALE: 'Compra',
    LOAN: 'Préstamo',
  },

  monitoredAthlete: {
    monitorado: 'Sí',
    naomonitorado: 'No',
  },

  europeanPassport: {
    'possui-passaporte': 'Sí',
    'nao-possui-passaporte': 'No',
  },

  erros: {
    text1: 'Datos no encontrados.',
    text2: 'No se pudieron buscar las agencias en este momento.',
    text3: 'No se pudieron buscar los detalles de la agencia en este momento.',
    text4: 'No se pudieron buscar los jugadores de esta agencia en este momento.',
    text5: '¡Jugador marcado para tu agencia con éxito!',
    text6: '¡Reporte enviado con éxito!',
    text7: '¡Jugador desmarcado con éxito!',
    text8: 'No se pudo realizar la acción en este momento.',
    text9: 'Jugador',
    text10: 'Fallo en la carga de la evaluación.',
    text11: 'Se produjo un error al intentar buscar las notas. Por favor, inténtalo de nuevo.',
    text12: 'Se produjo un error al intentar crear la nota. Por favor, inténtalo de nuevo.',
    text13: 'No se pudo encontrar la lista de jugadores.',
    text14: 'No se pudo encontrar el historial de rendimiento del jugador.',
    text15: 'No se pudieron encontrar los partidos del jugador.',
    text16: 'No se pudo encontrar el historial de contratos en la CBF del jugador.',
    text17: 'No se pudo encontrar el historial de transferencias del jugador.',
    text18: 'Se produjo un error al intentar actualizar el jugador. Por favor, inténtalo de nuevo.',
    text19: 'No se pudo encontrar la lista de países.',
    text20: 'No se pudieron buscar las evaluaciones en este momento. Por favor, inténtalo de nuevo.',
    text21: 'No se pudieron eliminar las evaluaciones en este momento. Por favor, inténtalo de nuevo.',
    text22: 'Se produjo un error al intentar buscar los últimos jugadores vistos. Por favor, inténtalo de nuevo.',
    text23: 'Se produjo un error al intentar buscar los últimos movimientos. Por favor, inténtalo de nuevo.',
    text24: 'Se produjo un error al intentar buscar los detalles del sistema. Por favor, inténtalo de nuevo.',
    text25: 'Se produjo un error al intentar buscar las ventanas de transferencia. Por favor, inténtalo de nuevo.',
    text26: 'No se pudo encontrar la lista de jugadores monitoreados.',
    text27: 'No se pudo guardar la lista de jugadores monitoreados.',
    text28: 'No se pudieron cambiar las listas de jugadores monitoreados.',
    text29: 'No se pudo agregar al monitoreo.',
    text30: 'Se produjo un error al intentar actualizar la organización. Por favor, inténtalo de nuevo.',
    text31: 'No se pudieron buscar los proyectos en este momento.',
    text32: 'No se pudieron buscar los detalles del proyecto en este momento.',
    text33: 'Se produjo un error al intentar buscar los informes. Por favor, inténtalo de nuevo.',
    text34: 'Se produjo un error al intentar buscar el informe. Por favor, inténtalo de nuevo.',
    text35: 'Se produjo un error al intentar generar el PDF del informe. Por favor, inténtalo de nuevo.',
    text36: 'Se produjo un error al intentar generar el informe. Por favor, inténtalo de nuevo.',
    text37: 'Se produjo un error al intentar actualizar el informe. Por favor, inténtalo de nuevo.',
    text38: 'No se pudieron eliminar los informes en este momento. Por favor, inténtalo de nuevo.',
    text39: 'No se pudo encontrar la lista de equipos.',
    text40: 'No se encontraron datos de los filtros.',
    text41: 'Usuario y/o contraseña inválidos.',
    text42: 'Datos inválidos.',
    text43: 'Se produjo un error al intentar buscar los datos del perfil. Por favor, inténtalo de nuevo.',
    text44: 'Se produjo un error al intentar actualizar el perfil. Por favor, inténtalo de nuevo.',
    text45: '¡Contraseña actualizada con éxito!',
    text46: 'Se produjo un error al intentar cambiar la contraseña. Por favor, inténtalo de nuevo.',
    text47: 'Fallo en la carga del esquema de evaluación.',
    text48: 'Datos de inscritos en la competencia no encontrados.',
    text49: 'Los datos son incorrectos. Inténtalo de nuevo.',
    text50: 'Formato de imagen no admitido. Envía imagenes en formato *.png ou *.jpg ou *.jpeg ou *.gif ou *.bmp ou *.webp.',
    link: 'Las redes sociales/sitio web deben completarse con un enlace válido',
    generalForm: 'Todos los campos con * deben ser completados',
    ageMinMax: 'La edad mínima debe ser inferior a la edad máxima',
  },

  rest: {
    box1: 'Recortar imagen para subir',
    box2: 'Realiza la primera anotación para el jugador',
    box3: 'Ingresa tu observación',
    box4: 'en este proyecto',
    box5: 'Nota',
    box6: 'Adjuntos',
    box7: 'Agregar archivo',
    box8: 'Incluir Dictamen',
    box9: 'Incluir Contexto',
    box10: 'Incluir aspectos',
    box11: 'Incluir microaspectos',
    box12: 'Selecciona una evaluación de la lista',
    box13: 'No hay datos disponibles sobre la carrera del jugador',
    box14: 'No hay datos disponibles sobre las transferencias del jugador',
    box15: 'No hay datos disponibles sobre los contratos del jugador',
    box16: 'Editar Imagen',
    box17: 'Insertar Imagen',
    box18: 'jugadores analizados',
    box19: 'Se produjo un problema al eliminar al jugador. Por favor, inténtalo de nuevo.',
    box20: 'Se produjo un problema al actualizar los datos del jugador. Por favor, inténtalo de nuevo.',
    box21: 'jugador',
    box22: 'Eliminar todos',
    box23: 'No se ha ingresado ninguna anotación.',
    box24: 'Paso 1: Selecciona el jugador a anunciar',
    box24other: '* Solo puedes anunciar deportistas sin contrato.',
    box25: 'Continuar',
    box26: 'Audiencia',
    box27: 'clubes seleccionados',
    box28: 'Período de publicación',
    box29: 'Tipo de negociación deseada',
    box30: 'Valor de venta',
    box31: 'Salario anual del jugador',
    box32: 'Observaciones',
    box33: 'Valor del préstamo',
    box34: 'Buscar',
    box35: 'Agregar nueva lista',
  },

  trello: {
    box1: 'Obtén más información sobre los clubes más grandes del mundo y sus jugadores.',
    box2: 'Aún no tienes acceso? Solicitar una demostración.',
    box3: 'Quiero saber Footlink',
    box4: 'El futuro de tu juego pasa por aquí.',
    box5: 'Consulta información sobre los clubes brasileños y sus plantillas.',
    box6: 'Consulta los clubes que son clientes de Footlink.',
  },

  languages: {
    br: 'Portugués (Brasileño)',
    pt: 'Portugués',
    eng: 'Inglés',
    esp: 'Español',
  },

  professionals: {
    topbarTitle: 'Red de Profesionales',
    topbarSubtitle:
      'Administra y desarrolla tu red de contactos con la mayor red de profesionales de fútbol en Brasil.',

    filtersTopspan: 'Ya somos una red de 1.450 profesionales.',
    filtersInputName: 'Nombre',
    filtersSelectLevel: 'Nivel: Todos',
    filtersSelectOrganization: 'Organización: Todos',

    connectButton: 'Conectar',
    connectedButton: 'Conectado',
    pendingButton: 'Pendiente',
    sendMessageButton: 'Enviar mensaje',

    inviteTitle: 'Invitaciones',
    inviteSpan: 'Invita a tus contactos a formar parte de la mayor red de profesionales de fútbol en Brasil.',
    inviteButton: 'Enviar invitación',
    inviteAccept: 'Aceptar',
    inviteIgnore: 'Ignorar',
    invite: 'No hay invitaciones para mostrar',

    networkTitle: 'Mi Red',
    networkSpan: 'Administra y amplía tu red de contactos con Footure Pro.',
    networkConnections: 'Conexiones',
    networkFollow: 'Seguir',
    networkFollowing: 'Siguiendo',
    networkFollowers: 'Seguidores',

    description: 'Descripción',
    socialMedia: 'Redes sociales',
    language: 'Idiomas',
    connections: 'Conexiones cercanas',
    commonConnections: '+ Ver todas las conexiones comunes',
  },

  listTranslation: {
    Amateur: 'Amateur',
    Female: 'Femenino',
    Male: 'Masculino',
  },

  navDrawer: {
    myperfil: 'Mi perfil',
    organization: 'Organización',
    module: 'Módulos',
    name: 'Nombre del Club',
    perfil: 'ver perfil de la organización',
    feed: 'Mi Feed',
    search: 'Búsqueda de Jugadores',
    club: 'Clubes',
    meuClube: 'Mi Club',
    competitions: 'Competencias',
    analysis: 'Análisis de Mercado',
    market: 'Mercado de Transferencias',
    rede: 'Red de Profesionales',
    agencies: 'Agencias',
  },

  chat: {
    placeholder: 'Buscar Mensajes',
    from: 'Tú',
    select_message: 'Selecciona un mensaje',
    view_club_page: 'Ver página del club',
    view_athlete_page: 'Ver página del atleta',
    view_agent_page: 'Ver página del agente',
    organization_messages: 'Mensajes de la organización',
    my_messages: 'Mis mensajes',
    connections: 'Conexiones',
    negotiations: 'Negociaciones',
    clubs: 'Clubes',
    athletes: 'Atletas',
    agents: 'Agentes',
    message_clube: 'Mensaje del club',
    select_category: 'Selecciona una categoría',
    no_chat: 'No hay mensajes para mostrar',
    talk_to_agent: 'Hablar con agente',
    talk_to_club: 'Hablar con club',
    athlete_club: 'Club del Atleta',
    athlete_agency: 'Agente del Atleta',
    contract_until: 'Hasta',
    deleted: 'Este mensaje ha sido eliminado',
    deletedNote: 'Esta nota ha sido eliminada',
  },

  transferMarket: {
    offeredBy: 'Ofrecido Por',
    attention: 'Atención !',
    dialogMessage: 'Cuando muestras interés en un atleta, se iniciará una nueva negociación y el nombre de tu Club se mostrará en la negociación incluso si es "Anónimo".',
    wantToContinue: '¿Quieres continuar?',
    search_page_desc: 'anunciado en el Mercado de Transferencias con estos atributos al momento',
    search_page_button: 'Ver Mercado de Transferencias',
    subtitle: 'Negocia con los mejores clubes de Brasil y el mundo y refuerza tu equipo.',
    available_negotiation: ' atletas disponibles para negociación',
    filterMessagesPlaceholder: 'Buscar mensajes',
    filterTransferTypePlaceholder: 'Todos los atletas',
    filterTransferTypeBuy: 'Llegadas',
    filterTransferTypeSell: 'Salidas',
    filterTransferTypeSALE: 'Venta',
    filterTransferTypeLOAN: 'Préstamo',
    editAd: 'Editar Anuncio',
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Precio Pedido',
    headerTransferTypeTitle: 'Tipo de Negociación',
    headerSalaryTitle: 'Salario',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venta',
    headerClubObs: 'Notas del anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Escribe tu mensaje',
    headerOtherChatTitle: '3 conversaciones sobre',
    headerOtherChatAlertTitle: 'Existen otras 3 conversaciones abiertas sobre este jugador',
    headerOtherChatAlertLink: 'Ver conversaciones',
    headerChatInactive: 'Este deportista ya no está en el mercado de fichajes',
    moreInfo: 'Más Información',
    announcedIn: 'Anunciado en',
    business: 'Negocio',
    amountRequested: 'Precio Pedido',
    sale: 'Venta',
    year: 'Año',
    clubAnnouncement: 'Detalles del anuncio',
    active: 'Activo',
    inactive: 'Inactivo',
    adData: 'Datos del Anuncio',
    defineAudience: 'Definir Audiencia',
    confirmAd: 'Confirmar Anuncio',
    publish: 'Publicar',
    publishSuccess: '¡Anuncio publicado con éxito!',
    step1title: 'Paso 1: Introduce las características del deportista que buscas',
    step2title: 'Paso 2: Ingresa los datos del anuncio',
    step3title: 'Paso 3: Define quién puede ver el anuncio',
    step4title: 'Paso 4: Confirma los datos del anuncio antes de publicar',
    step1error: 'Selecciona un atleta para continuar',
    step2error: 'Por favor complete todos los campos antes de continuar',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    adAuciton: 'Audiencia del Anuncio',
    showAll: 'Todos pueden ver',
    showAgents: 'Mostrar anuncio para agentes',
    showAllClubs: 'Todos los clubes pueden ver',
    selectShow: 'Definir quién puede ver',
    selectClubsShow: 'Establecer qué clubes pueden ver',
    negotiationType: 'Tipo de Negociación Deseada',
    sale_value: 'Precio de Venta',
    sale_value_desired: 'Valor de la Compra',
    loan_value: 'Valor del Préstamo',
    annual_salary: 'Salario Anual del Atleta',
    gross_salary: 'Salario Bruto',
    perYear: 'por año',
    perMonth: 'por mes',
    publishFail: '¡Error al publicar el anuncio!',
    completeProfile: 'Ver perfil completo',
    evaluationFooture: 'EVALUACIÓN FOOTURE',
    openChat: 'Abrir conversaciones',
    athleteInfo: 'Información del Atleta',
    modalError1: 'No es posible crear más de un anuncio para el mismo atleta.',
    modalError2: 'Seleccione un atleta para continuar.',
    modalError3: 'Se superó el límite del plan.',
    modalError4: 'Usuario activo en otro dispositivo.',
    withoutClub: 'Ningún club',
    announceType: '¿Qué tipo de anuncio desea crear?',
    mySquadAthlete: 'Anunciar atleta de mi equipo',
    AthleteProfile: 'Anunciar el perfil del atleta deseado',
    gbe: 'GBE',
    moreCharacteristics: 'Más características',
    nameConfidential: '¿Mantener confidencial el nombre de mi club?',
    infoNameConfidential: 'Cuando se marca como "Sí", el nombre de su club aparecerá como "Confidencial" en la lista de anuncios. El nombre de tu club solo aparecerá para los clubes o agentes de los atletas en los que muestres interés.',
    atlheteOffers: 'Ofertas para atletas',
    athletesOffered: 'Atletas Ofrecidos',
    athletesInConversation: 'Atletas en conversación',
    discardedAthletes: 'Atletas Descartados',
    movedTo: 'Este atleta ha sido movido a la pestaña ',
    showInterest: 'Muestra interés',
    discard: 'Descartar',
    interestedAgency: 'Agencia interessada',
    interestedClub: 'Club Interesado',
    clubObservations: 'Observaciones del club',
    maxValue: 'Valor máximo',
    maxSalary: 'Salario Bruto Máximo',
    clubAnnounce: 'Anuncio del Club',
    desiredProfile: 'Perfil del Atleta deseado',
    otherFeatures: 'Otras características',
    advancedSearch: {
      desiredAthletes: 'Atletas deseados',
      subtitle: 'Negociar con los clubes más grandes de Brasil y del mundo y hacer grandes negocios.',
      postedIn: 'Publicado en',
      desiredPosition: 'Posición deseada',
      age: 'Edad',
      profile: 'Perfil',
      sex: 'Sexo',
      club: 'Club',
      clubSeries: 'Serie de clubes',
      country: 'País',
      business: 'Negocio'
    },
    athleteList: {
      youHave1: 'Tienes ',
      youHave2: ' deportistas que encajan en el perfil deseado',
      offered: 'Atleta ofrecido',
      toOffer: 'Oferta Atleta',
      goToNegotiation: 'Ir a negociación'
    },
    textError1: 'Hasta el momento no se han ofrecido atletas',
    textError2: 'No tienes deportistas con el perfil que busca el club'
  },

  excededPlanLimit: {
    h4_1: 'Actualiza tu cuenta y',
    h4_2: 'tener acceso a este y otros módulos!',
    h5_1: 'Vea los beneficios de actualizar su cuenta de Footlink:',
    h5_2: '¡Es mucho más!',
    h6_1: 'Acceso a la mayor base de datos de atletas brasileños del mercado',
    h6_2: 'Acceso a datos actualizados de vencimiento de contratos de atletas',
    h6_3: 'Acceso a la mayor base de datos de competiciones juveniles de Brasil',
    h6_4: 'Herramientas para digitalizar y organizar tu proceso de análisis de mercado',
    button: 'Hable con un consultor de Footlink',
  },

  negotiations: {
    headerClubTitle: 'Anunciante',
    headerPriceTitle: 'Valor pedido',
    headerTransferTypeTitle: 'Tipo de negocio',
    headerSalaryTitle: 'Salario',
    headerBuyTransferTypeText: 'Compra',
    headerSellTransferTypeText: 'Venta',
    headerSaleTransferTypeText: 'Venta',
    headerLoanTransferTypeText: 'Préstamo',
    headerClubObs: 'Notas del anunciante',
    linkProfile: 'Ver perfil completo',
    chatMessageInput: 'Escribe tu mensaje',
    headerOtherChatTitle: '3 conversaciones sobre',
    headerOtherChatAlertTitle: 'Existen otras {{length}} conversaciones abiertas sobre este jugador',
    headerOtherChatAlertLink: 'Ver conversaciones',
    errorChatCreate: 'No se pudo guardar tu mensaje, verifica el mensaje e intenta nuevamente',
    moreInfo: 'Más Información',
    announcedIn: 'Anunciado en',
    business: 'Negocios',
    amountRequested: 'Valor pedido',
    sale: 'Venta',
    year: 'Año',
    clubAnnouncement: 'Detalles del anuncio',
    active: 'Activo',
    inactive: 'Inactivo',
    adData: 'Datos del Anuncio',
    defineAudience: 'Definir Audiencia',
    confirmAd: 'Confirmar Publicación',
    publish: 'Publicar',
    publishSuccess: '¡Anuncio publicado con éxito!',
    confidentialAd: '¿Anuncio confidencial?',
    step1title: 'Paso 1: Introduce las características del deportista que buscas',
    step2title: 'Paso 2: Ingresa los datos de tu anuncio',
    step3title: 'Paso 3: Define quién podrá ver el anuncio',
    step4title: 'Paso 4: Confirma los datos del anuncio antes de publicar',
    select: 'Seleccionar',
    selected: 'Seleccionado',
    adAuciton: 'Audiencia del Anuncio',
    showAll: 'Todos pueden ver',
    selectShow: 'Definir quién puede ver',
    negotiationType: 'Tipo de negociación deseada',
    sale_value: 'Valor de venta',
    loan_value: 'Valor del préstamo',
    annual_salary: 'Salario anual del atleta',
    perYear: 'por año',
    noMessages: 'Aún no hay mensajes en esta conversación.',
    selectChat: '¡Selecciona una conversación para comenzar a negociar!',
  },

  search: {
    typeNegotiation: 'Tipo de Negociación',
    valueForSale: 'Valor para Venta (en €)',
    valueForPurchase: 'Valor para Compra (em €)',
    salary: 'Salario (en €)',
  },

  divisionOptions: {
    division: 'Serie',
    1: 'Serie A',
    2: 'Serie B',
    3: 'Serie C',
    4: 'Serie D',
  },

  transferWindows: {
    onlyMonitored: 'Sólo monitoreado',
    onlyOpen: 'Sólo abierto',
  },

  whatsapp: {
    phone: '5551995145721',
    valuation1: 'Me gustaría comprar información de valoración del atleta ',
    valuation2: ' de ',
    upgrade: 'Hola! Quiero hacer un Upgrade de mi cuenta Footlink!',
  },

  criteriaOptions: {
    '': 'Sin criterios',
    season: 'Por temporada',
    competition: 'Por competencia',
    team: 'Por club',
    teamInComp: 'Por club en la competencia',
    birthyear: 'Por año de nacimiento',
    position: 'Por posición',
    minutes_played: 'Minutos jugados',
    athlete__birthdate: 'Fecha de Nacimiento',
  },

  clubDivisionOptions: {
    undefined: 'División',
    1: '1a División - Nacional',
    2: '2a División - Nacional',
    3: '3a División - Nacional',
    4: '4a División - Nacional',
  },

  agencyAthletes: {
    title: 'Atletas de agencia',
    subTitle: 'atletas en la cartera de esta agencia',
  },

  firstAccess: {
    text1: 'Enhorabuena',
    text2: 'ha recibido una',
    text3: 'Licencia CBF',
    text4: 'para conectarse a Footlink!',
    text5:
      'Estás a un paso de conectarte a Footlink, la plataforma más completa de Brasil para gestionar tu proyecto deportivo',
    text6: 'Activar tu cuenta Footlink es rápido, simple y sin complicaciones',
    text7: '¿Empezamos?',
    text8: 'Comenzar la activación de la cuenta',
    text9: 'Para empezar',
    text10: 'introduce ',
    text11: 'contraseña temporal',
    text12: ' que ',
    text13: 'que recibió por correo electrónico',
    text14: 'Contraseña temporal',
    text15: 'Enviar',
    text16: 'A continuación, introduce el código que ',
    text17: 'has recibido en el correo electrónico',
    text18: 'Código',
    text19: '¿No has recibido el código? Puedes solicitarlo de nuevo en ',
    text20: '¿No has recibido el código?',
    text21: 'Contraseña no válida o el usuario ya está activo.',
    text22: 'Datos Personales',
    text23: 'Visível somente para personas de su organización',
    text24: 'Crear contraseña',
    text25: 'Por tu seguridad, cambia tu contraseña frecuentemente',
    text26: 'Su contraseña debe contener números, letras y caracteres especiales y tener al menos 8 caracteres.',
    text27: 'Estoy de acuerdo con los términos de uso y política de privacidad.',
    box1: 'Solicite que se lo envíen de nuevo',
    box2: 'Confirma tu organización',
    box3: 'Para que podamos proceder a la activación de su cuenta, compruebe que su',
    box4: 'Su organización es correcta',
    box5: '¡Sí, es correcta!',
    box6: '¿No es su organización? Póngase en ',
    box7: 'contacto con nosotros',
    box8: 'Cree su perfil',
    box9: 'Ahora cree su perfil y sus datos de acceso',
    box10: 'Crea tu perfil',
    box11: '¡Bienvenido a ',
    box12: 'Ahora tienes acceso a la plataforma más completa de Brasil',
    box13: 'Para empezar a utilizar Footlink, inicia sesión en la plataforma con los datos de acceso',
    box14: 'Introduce tus datos de acceso',
    box15: '¿Quieres saber más sobre Footlink? Mira el siguiente vídeo:',
    box16: '¿Ya tienes una cuenta?',
    box17: 'Inicia sesión',
    box18: 'Footlink',
    box19: 'para la gestión de tu proyecto deportivo',
    box20: 'que has registrado ahora.',
    errorUser: 'Todos los campos son obligatorios.',
    errorPassword: 'Por favor revisa tu contraseña.',
    tokenError: 'El token no válido o usuario ya está activo.',
  },

  modalErroPermissionProjeto: {
    text1: 'No tienes permiso para ver este proyecto.',
    text2: 'Póngase en contacto con los colaboradores del proyecto para solicitar acceso.',
  },

  foot: {
    right: 'Diestro',
    left: 'Zurdo',
    ambidextrous: 'Ambidiestro',
    indifferent: 'Indiferente',
  },

  weekDays: {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
  },

  gameFields: {
    competition: 'Competición',
    level: 'Nivel de competición',
    gameLevel: 'Nivel de juego',
    location: 'Lugar del juego',
    observed: 'Juego observado',
    date: 'Fecha del juego',
    minutes: 'Minutos jugados',
    startingPlayer: 'Comenzó como titular',
    category: 'Categoría',  
    position: 'Posición en el juego',
    stature: 'Altura',
    foot: 'Pie preferido',
    gols: 'Goles',
    assistances: 'Asistencias',
    yellowCards: 'Tarjetas amarillas',
    redCards: 'Tarjetas rojas',
    performance: 'Rendimiento'
  },

  statureOptions: {
    alto: 'Alto',
    medio:  'Medio',
    baixo:  'Bajo',
  },

  categoryOptions: {
    profissional: 'Profesional',
    amador: 'Amador',
    sub23: 'Sub-23',
    sub22: 'Sub-22',
    sub21: 'Sub-21',
    sub20: 'Sub-20',
    sub19: 'Sub-19',
    sub18: 'Sub-18',
    sub17: 'Sub-17',
    sub16: 'Sub-16',
    sub15: 'Sub-15',
    sub14: 'Sub-14',
    sub13: 'Sub-13',
    sub12: 'Sub-12',
    sub11: 'Sub-11',
    sub10: 'Sub-10',
    sub9: 'Sub-9',
  },
  evaluationData: 'Datos de evaluación',
  charts: {
    byPosition: 'Orientación por posición',
    forContract: 'Dirigido a Contratación',
    byYear: 'Por año de nacimiento',
    evaluations: 'Evaluaciones realizadas',
    athletes: 'Atletas Evaluados',
    byAnalist: 'Por Analista',
    filterByPosition: 'Por Posición',
    filterByPeriod: 'Por Periodo',
    filterByTargeting: 'Por Dirección',
    moreFilters: 'Más Filtros',
    noDirection: 'Sin Dirección',
    noData: 'Hemos tenido un problema al cargar los gráficos, inténtalo de nuevo más tarde.',
    byMomentClub: 'Por Club en el Momento de la Evaluación',
    byActualClub: 'Por Club Actual',
  },

  avaliationModel: {
    GOLEIROS: 'Arqueros',
    LATERAIS: 'Laterales',
    ZAGUEIROS: 'Defensas',
    VOLANTES: 'Volantes',
    'MEIAS-CENTRAIS': 'Medios Centros',
    EXTREMOS: 'Extremos',
    'ATACANTES-REFERENCIA': 'Atacante Referencia',
    GERAL: 'Base General',
    'RELATORIO-DE-JOGO': 'Reporte de Partido',
  },
 
  dashboard: {
    clubs: 'Clubes',
    numer_of_clubs: 'Nº de Clubes',
    agents: 'Agentes',
    numer_of_agents: 'Nº de Agentes',
    total_count_per_project: 'Por Proyectos',
    total_count_per_license: 'Por Licencias',
    total_count_division: 'Por División',
    total_count_agency: 'Por Categoría',    
    total_count_per_athlete: 'Por Atletas',
    total_count_agency_global: 'Global',
    total_count_agency_latam: 'Latam',
    total_count_agency_brazil: 'Brasil',
    total_count_division_1: 'División 1',
    total_count_division_2: 'División 2',
    total_count_division_3: 'División 3',
    total_count_division_4: 'División 4',
    total_count_division_null: 'Sin División',
    total_count_foreign: 'Extranjeros',
    transfer_market: 'Mercado de transferencias',
    number_of_ads: 'Nº de Anuncios',
    transfer_market_filter: 'Incluir anuncios inactivos',
    total_announcement_by_type: 'Por Tipo',    
    by_advertiser_count: 'Por Anunciante',
    by_negotiation_count: 'Por Negociaciones',
    total_announcement_athletes_team: 'Atleta del Equipo',
    total_announcement_desired_athletes: 'Atleta Deseado',
    total_announcement_athletes_agency: 'Atleta de Agencia',
    market_analysis: 'Análisis de Mercado',
    number_of_monitored_players: 'Nº de Atletas Monitorados',
    total_count_athletes_monitored_per_org: 'Por Monitoramiento',
    total_count_evaluations_per_org: 'Por Evaluaciones',
    total_count_reports_per_org: 'Por Reportes',
    chat: 'Chat',
    number_of_chats: 'Nº de Conversaciones',
    from_origin: 'Por Origen',
    from_organization: 'Por Organización',
    from_users: 'Por Usuarios',
    total_count_agency_to_club: 'Negociaciones - Agente > Club',
    total_count_club_to_club: 'Negociaciones - Club > Club',
    total_count_athlete_club_to_club: 'Atleta - Club > Club',
    total_count_athlete_agency_to_club: 'Atleta - Agente > Club',
    total_count_athlete_agency_to_agency: 'Atleta - Agente > Agente',    
    total_count_team_club_to_agency: 'Club - Club > Agente',
    total_count_athlete_club_to_agency: 'Club - Club > Agente',
    total_count_team_agency_to_club: 'Agente - Agente > Club',
    total_count_team_agency_to_agency: 'Agente - Agente > Agente',
    total_count_team_club_to_club: 'Club - Club > Club',
    last_30: 'Últimos 30 días',
    last_60: 'Últimos 60 días',
    last_90: 'Últimos 90 días',
  }
};
