import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Creators } from 'store/ducks/athlete';
import { useTranslation } from 'react-i18next';
import { Card, Spinner, Menu } from 'components/template';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import styles from './Evaluations.module.scss';
import EvaluationsTable from 'components/EvaluationsTable/EvaluationsTable';
import IconButtonText from 'components/template/IconButtonText/IconButtonText';
import moment from 'moment';

export default function Evaluations({ athleteId }) {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const evaluations = useSelector(state => state.athlete.evaluationsList);

  const initialFilters = {
    analyst: [],
    club: [],
    date: [],
  };

  const [analysts, setAnalysts] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [dates, setDates] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    dispatch(Creators.getEvaluationsList(athleteId));

    return () => {
      dispatch(Creators.resetEvaluationsList());
    };
  }, [dispatch, athleteId]);

  useEffect(() => {
    const uniqueNames = new Set();
    const uniqueClubs = new Set();
    const uniqueDates = new Set();

    evaluations.data.forEach(evaluation => {
      if (evaluation.author === undefined) {
        return;
      }
      uniqueNames.add(evaluation.author);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.team.name === undefined) {
        return;
      }
      uniqueClubs.add(evaluation.team.name);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.date === undefined) {
        return;
      }

      uniqueDates.add(moment(evaluation.date).format('DD/MM/YYYY'));
    });

    setAnalysts(Array.from(uniqueNames));
    setClubs(Array.from(uniqueClubs));
    setDates(Array.from(uniqueDates));
  }, [evaluations]);

  const filteredEvaluations = evaluations.data.filter(evaluation => {
    const analystFilter = filters['analyst'];
    const clubFilter = filters['club'];
    const dateFilter = filters['date'];

    if (
      (analystFilter.length === 0 || analystFilter.includes(evaluation.author)) &&
      (clubFilter.length === 0 || clubFilter.includes(evaluation.team.name)) &&
      (dateFilter.length === 0 || dateFilter.includes(moment(evaluation.date).format('DD/MM/YYYY')))
    ) {
      return true;
    }

    return false;
  });

  let content = '';
  if (evaluations.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (evaluations.error) {
    content = (
      <Card bordered softShadow className={styles.evaluations}>
        <span>{t(evaluations.error)}</span>;/
      </Card>
    );
  } else if (evaluations.data) {
    content = (
      <div className={styles.evaluations}>
        <div className={styles.header_container}>
          <span className={styles.header}>{t('field.re_ava1')}</span>
          <span className={styles.sub_header}>{t('field.re_ava2')}</span>
        </div>
        <div className={styles.filter_container}>
          <span>{t('proj.filtrarPor')}</span>
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('field.filter1')}
            placeholder={t('field.filter1')}
            options={analysts.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['analyst']}
            setValue={val => {
              setFilters({ ...filters, analyst: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, analyst: [] });
            }}
          />
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('field.filter2')}
            placeholder={t('field.filter2')}
            options={clubs.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['club']}
            setValue={val => {
              setFilters({ ...filters, club: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, club: [] });
            }}
          />
          <Menu
            className={styles.menu}
            title={t('field.filter3')}
            placeholder={t('field.filter3')}
            rounded
            options={dates.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['date']}
            setValue={val => {
              setFilters({ ...filters, date: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, date: [] });
            }}
            allowEmpty
          />
          <IconButtonText
            onClick={() => history.push(`${location.pathname}/nova`)}
            color="lightPurple1"
            text={t('field.add_ava')}
            icon={<BsFillPlusCircleFill />}
          />
        </div>

        <EvaluationsTable
          evaluations={!filteredEvaluations || evaluations?.data?.length === 0 ? [] : filteredEvaluations}
        />
      </div>
    );
  }

  return <>{content}</>;
}

Evaluations.propTypes = {
  athleteId: PropTypes.string.isRequired,
};
