import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as layoutCreators } from 'store/ducks/layout';
import { Creators as notificationsCreators } from 'store/ducks/notifications';
import styles from './Layout.module.scss';
import AppBar from './AppBar/AppBar';
import NavDrawer from './NavDrawer/NavDrawer';
import { Toast, ExcededPlanLimit } from 'components/template';

//firebase
import { firebase, firestore, signInFirebase, firebaseIsLogged } from 'App/App';
import { doc, onSnapshot } from "firebase/firestore";

let _unsubscribeNotify;
let _reloadNotificationsTimeout;
export default function Layout({ children, isAppBarVisible = true, darkMode = false }) {
  const dispatch = useDispatch();
  const layout = useSelector(state => state.layout);
  const user = useSelector(state => state.user.data);

  if (layout.isAppBarVisible !== isAppBarVisible) {
    if (isAppBarVisible) {
      dispatch(layoutCreators.showAppBar());
    } else {
      dispatch(layoutCreators.hideAppBar());
    }
  }
  
  useEffect(() => {
    const loadFirebase = async () => {
      if (!firebaseIsLogged()) {
        await signInFirebase();
      }
      const orgId = user?.organization?.id?.id;
      if (orgId != null && _unsubscribeNotify == null) {
        const orgNotifyDocRef = doc(firestore, `org-notify-${process.env.REACT_APP_FIREBASE_SUFFIX}`, orgId.toString());
        _unsubscribeNotify = onSnapshot(orgNotifyDocRef, (snapshot) => {   
          if (_reloadNotificationsTimeout == null) {
            _reloadNotificationsTimeout = setTimeout(async () => {                
              dispatch(notificationsCreators.notifyUpdate());
              _reloadNotificationsTimeout = null;
            }, 3500);
          }
        });  
      }
    };
    loadFirebase();
    dispatch(notificationsCreators.loadNotificationsFromStorage());
    return () => {
      if (_unsubscribeNotify != null) {
        _unsubscribeNotify();
        _unsubscribeNotify = null;
      }
    };
  }, [firebase]); // eslint-disable-line react-hooks/exhaustive-deps

  const contentClasses = [styles.content];
  if (layout.isAppBarVisible) contentClasses.push(styles.appBarVisible);

  const hideNavDrawer = useCallback(() => {
    dispatch(layoutCreators.hideNavDrawer());
  }, [dispatch]);

  const navDrawerToggler = useCallback(() => {
    dispatch(layoutCreators.toggleNavDrawer());
  }, [dispatch]);

  function hideToast() {
    if (typeof layout.toast.onCloseHandler === 'function') layout.toast.onCloseHandler();
    dispatch(layoutCreators.hideToast());
  }

  return (
    <>
      {layout.isAppBarVisible && <AppBar toggleNavDrawer={navDrawerToggler} darkMode={darkMode} />}
      <NavDrawer isOpen={layout.isNavDrawerVisible} hide={hideNavDrawer} />
      <Toast
        isVisible={layout.toast.isVisible}
        content={layout.toast.content}
        type={layout.toast.type}
        onClose={hideToast}
        duration={layout.toast.duration}
      />
      <div className={contentClasses.join(' ')}>{children}</div>
      {layout.showExcededPlanLimit && (
        <div className={styles.excededPlanLimit}>
          <ExcededPlanLimit
            showBackground={true}
            closeClick={() => {
              dispatch(layoutCreators.hideExcededPlanLimit());
            }}
          />
        </div>
      )}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
