import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Checkbox, Spinner, Dialog, Pagination } from 'components/template';
import styles from './styles.module.scss';
import ActionBar from './ActionBar';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import TeamRow from 'components/TeamRow/TeamRow';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MonitoringButton from 'components/MonitoringButton/MonitoringButton';
import { getList, deleteMonitor } from 'store/ducks/monitoringV2';
import { getAthletes } from 'store/ducks/athleteV2';
import { usePagination } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

function Monitoring() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const monitoredAthletes = useSelector(state => state.athleteV2);
  const list = useSelector(state => state.monitoringV2);

  const [checkedAthletes, setCheckedAthletes] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const [pagination, setPagination] = usePagination();

  const isMounted = useRef(false);

  const today = new Date();

  const [selectedFilters, setSelectedFilters] = useState({
    custom_athletes: 'normal',
    name: '',
    foot: '',
    position: [],
    lists: [],
    min_birthyear: 1975,
    max_birthyear: Number(today.getFullYear()),
  });

  const [athletes, setAthletes] = useState([]);

  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (isMounted.current) {
      setAthletes(monitoredAthletes.athletes.data);
    }
  }, [monitoredAthletes.athletes]);

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredList(
      list.data.filter(item => item.name !== '_DEFAULT_').map(item => ({ label: item.name, value: String(item.id) }))
    );
  }, [list.data]);

  useEffect(() => {
    if (allChecked) {
      setCheckedAthletes([ ...athletes ]);
    } else {
      setCheckedAthletes([]);
    }
  }, [allChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted.current) {
      setAthletes(monitoredAthletes.athletes.data);
    }
  }, [selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAthletes({ ...pagination, watched: true, ...selectedFilters }));
    isMounted.current = true;
  }, [dispatch, pagination, selectedFilters]);

  function handleChange(athlete) {
    if (checkedAthletes.some(item => item.id === athlete.id)) {
      setCheckedAthletes(checkedAthletes.filter(item => item.id !== athlete.id));
    } else {
      setCheckedAthletes([...checkedAthletes, athlete]);
    }
  }

  function athleteListToTable(list) {
    return list.map(row => {
      // return [];

      const checkbox = (
        <Checkbox
          dark
          checked={checkedAthletes.some(athlete => athlete.id === row.id)}
          onChange={() => {
            handleChange(row);
          }}
        />
      );
      // const athlete = <AthleteRow name={row.name} img={row.img} id={row.id} />;
      const athlete = <AthleteRow img={row.photo} name={row.fullname} nickname={row.nickname} id={row.id} />;
      const nationalities = (
        <NationalitiesRow className={styles.nationalityFlag} nationalities={row?.nationalities} athleteId={row.id} />
      );
      // const loanedTeamContract = row?.loanedTeam?.endOfContract
      //    ? moment(row.loanedTeam.endOfContract).format('DD/MM/YYYY')
      //    : '';

      const age = (
        <span style={{ fontWeight: '500' }}>
          '{row.birthyear?.toString().substr(-2)} &#40;{row.age}&#41;
        </span>
      );
      // const endOfContract = row.contract_info.current_contract_end ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY') : '-';

      const monitoringButton = (
        <div className={styles.buttonContainer}>
          <MonitoringButton athlete={row} />
        </div>
      );

      let team = null;

      if (row.contract_info.loaned) {
        team = (
          <div className={styles.teamContainer}>
            <span>{row.src_team?.nickname}</span>
            <br />
            <span className={styles.contractDate}>
              {row.contract_info?.src_contract_end
                ? moment(row.contract_info?.src_contract_end).format('DD/MM/YYYY')
                : '-'}
            </span>
          </div>
        );
      }

      const loanedTeam = row?.contract_info?.loaned ? (
        <TeamRow name={team} id={row.src_team?.id} img={row.src_team?.emblem} />
      ) : (
        '-'
      );

      // return [checkbox, athleteImg, athlete, age, row.position, nationalities, endOfContract, assignorTeam, monitoringButton]

      const lassignorTeamInfo = (
        <div className={styles.teamContainer}>
          <span>{row.current_team?.nickname}</span>
          <br />
          <span className={styles.contractDate}>
            {row.contract_info?.current_contract_end
              ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY')
              : '-'}
          </span>
        </div>
      );

      const assignorTeam = row?.current_team?.nickname ? (
        <TeamRow name={lassignorTeamInfo} id={row.current_team.id} img={row.current_team.emblem} />
      ) : null;

      return [checkbox, athlete, assignorTeam, age, row.position, nationalities, loanedTeam, monitoringButton];
    });
  }

  const removeFromCheckedAthletes = async () => {
    setIsOpenDialog(false);
    setDeleting(true);
    const tasks = [];
    for (const athlete of checkedAthletes) {
      for (const listId of athlete.lists) {
        tasks.push(deleteMonitor(athlete.id, listId));
      }
    }
    
    await Promise.all(tasks);
    setDeleting(false);
    setSelectedFilters({ ...selectedFilters }); //reload athletes
  }

  let content = '';

  if (monitoredAthletes.athletes.loading || !isMounted.current || deleting) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (monitoredAthletes.athletes.error) {
    content = <p>{t(monitoredAthletes.error)}</p>;
  } else {
    content = (
      <div className={styles.tableContainer}>
        <Table
          flat
          theads={[
            <Checkbox dark checked={allChecked} onChange={() => setAllChecked(!allChecked)} />,
            t('athlete_list.box1'),
            <div className={styles.contract}>
              {t('agencies.club')}/{t('agencies.text3')}
            </div>,
            t('athlete_list.box3'),
            t('athlete_list.box4'),
            t('athlete_card.nation'),
            <div className={styles.contract}>
              {t('agencies.club')}/{t('agencies.text5')}
            </div>,
            '',
          ]}
          className={styles.monitoringTable}
          data={athleteListToTable(athletes)}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.content}>
        <Card softShadow className={styles.monitoring}>
          <ActionBar
            checkedAthletes={checkedAthletes}
            setIsOpenDialog={setIsOpenDialog}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            list={filteredList}
          />
          {content}
        </Card>
      </div>
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        mainTitle={t('home.box19')}
        btnLeft={t('dialog_content.cancel')}
        btnRight={t('home.box20')}
        btnLeftClick={() => setIsOpenDialog(false)}
        btnRightClick={() => removeFromCheckedAthletes()}
      >
        {t('home.text1')}
      </Dialog>
      <Pagination
        setPagination={setPagination}
        pagination={pagination}
        resourceDisplayName={t('filters.ath')}
        count={monitoredAthletes.athletes.count}
        length={monitoredAthletes.athletes.data.length}
        hasNext={monitoredAthletes.athletes.hasNext}
        hasPrevious={monitoredAthletes.athletes.hasPrevious}
      />
    </>
  );
}

export default Monitoring;
