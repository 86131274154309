import React, { useState } from 'react';
import Notes from '../Notes';
import { HiTrash } from 'react-icons/hi';
import styles from './index.module.scss';
import { deleteNotes, getNotes } from 'store/ducks/athleteNotes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const selectedIds = [];
const DeletableNotes = ({
    athleteId,
    calcHeight,
    hideNoteGroups,
    inputPosition,
    inputPostElement,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showRemove, setShowRemove] = useState(false);
    const [saving, setSaving] = useState(false);

    const selectedIdsChange = () => {
        setShowRemove(selectedIds.length !== 0);
    };

    selectedIds.push = function () {
        Array.prototype.push.apply(this, arguments);
        selectedIdsChange();
    };

    selectedIds.splice = function () {
        Array.prototype.splice.apply(this, arguments);
        selectedIdsChange();
    };

    const deleteClick = async () => {
        setSaving(true);
        const response = await deleteNotes(athleteId, selectedIds.map(e => e.id));
        if (response?.error) {
            alert(t(response.error));
        } else {
            dispatch(getNotes(athleteId));
        }
        selectedIds.splice(0, selectedIds.length);
        setShowRemove(false);
        setSaving(false);
    }

    const buttonClasses = [styles.deleteButton];
    if (saving) {
        buttonClasses.push(styles.saving);
    }

    return <>
        {showRemove && <HiTrash className={buttonClasses.join(' ')} onClick={deleteClick} />}
        <Notes
            athleteId={athleteId}
            calcHeight={calcHeight}
            hideNoteGroups={hideNoteGroups}
            inputPosition={inputPosition}
            inputPostElement={inputPostElement}
            noteListChecked={selectedIds}
        />
    </>
}

export default DeletableNotes;