/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { Input, Menu, SelectionButton } from 'components/template';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../YouthLeague.module.scss';
import { useTranslation } from 'react-i18next';
import { getCountries } from 'store/ducks/countries';
import { IoMdSearch } from "react-icons/io";

export default function ActionBar({ filters, selectedFilters, setSelectedFilters, gender, setGender, isCurrentSeasonTab }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countries = useSelector(state => state.countries.countries);
  const countriesData = countries.data ?? [];

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const [timeoutId, setTimeoutId] = useState(null);

  const handleInputChange = value => {
    clearTimeout(timeoutId);

    setTimeoutId(
      setTimeout(() => {
        applyFilters({ ...selectedFilters, name: value });
      }, 800)
    );
  };

  function applyFilters(newFilters) {
    setSelectedFilters(newFilters);
  }

  const currentCategoryName = selectedFilters.category ? filters.categories?.find(c => c.id === selectedFilters.category)?.name : null;
  return (
    <div className={styles.filterContainer}>
      <SelectionButton
        items={[
          { label: t('listTranslation.Male'), value: 'M' },
          { label: t('listTranslation.Female'), value: 'F' },
        ]}
        selected={gender}
        onClick={val => {
          if (setGender != null) {
            setGender(val);
          }
        }}
      />
      <Input
        rounded
        containerClassName={styles.filterInputContainer}
        inputClassName={styles.filterInput}
        icon={<IoMdSearch className={styles.searchIcon} />}
        iconPosition="right"
        placeholder={t('match.comp')}
        onChange={event => handleInputChange(event.target.value)}
      />
      <Menu
        title={t('compe.text1')}
        rounded
        allowEmpty
        placeholder={selectedFilters.country === '' ? t('compe.text1') : selectedFilters.country}
        className={styles.menu}
        options={filters.countries?.map(country => {
          const countryObj = countriesData.find(c => c.code === country);
          return {
            value: country,
            label: countryObj ? countryObj.name : country,
          };
        })}
        setValue={value => applyFilters({ ...selectedFilters, country: value })}
        value={selectedFilters.country}
        onClear={() => applyFilters({ ...selectedFilters, country: '' })}
      />
      <Menu
        title={t('compe.text2')}
        rounded
        allowEmpty
        placeholder={selectedFilters.context === '' ? t('compe.text2') : selectedFilters.context}
        className={styles.menu}
        setValue={value => applyFilters({ ...selectedFilters, context: value })}
        options={filters.context?.map(context => ({ value: context, label: t(`compe.context.${context}`) }))}
        value={selectedFilters.context}
        onClear={() => applyFilters({ ...selectedFilters, context: '' })}
      />
      {isCurrentSeasonTab ? (
        <Menu
          title={t('contact_card.category')}
          rounded
          allowEmpty
          placeholder={
            currentCategoryName == null 
              ? t('contact_card.category') 
              : (currentCategoryName === 'Profissional' ? t('compe.text26') : currentCategoryName)
          }
          className={styles.menu}
          options={filters.categories?.filter(c => c.gender === gender).map(category => ({
            value: category.id,
            label: category.name === 'Profissional' ? t('compe.text26') : category.name,
          }))}
          setValue={value => applyFilters({ ...selectedFilters, category: value })}
          value={selectedFilters.category}
          onClear={() => applyFilters({ ...selectedFilters, category: null })}
        />
      ) : (
        <Menu
          title={t('slider_menu.seas')}
          rounded
          allowEmpty
          placeholder={selectedFilters.season__name === '' ? t('slider_menu.seas') : selectedFilters.season__name}
          className={styles.menu}
          options={filters.seasons?.map(season => ({ value: season, label: season }))}
          setValue={value => applyFilters({ ...selectedFilters, season__name: value })}
          value={selectedFilters.season__name}
          onClear={() => applyFilters({ ...selectedFilters, season__name: '' })}
        />
      )}
    </div>
  );
}

ActionBar.propTypes = {
  // nameInputValue: PropTypes.string.isRequired,
  // nameInputChangeHandler: PropTypes.func.isRequired,
  // selectedFilters: PropTypes.object.isRequired
};
