import ErrorMessages from 'shared/ErrorMessages';
import Endpoints from 'endpoints';
import axios from 'api';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'monitoringV2/GET',
  GET_SUCCESS: 'monitoringV2/GET_SUCCESS',
  GET_FAILED: 'monitoringV2/GET_FAILED',
  POST: 'monitoringV2/POST',
  POST_SUCCESS: 'monitoringV2/POST_SUCCESS',
  POST_FAILED: 'monitoringV2/POST_FAILED',
  DELETE: 'monitoringV2/DELETE',
  DELETE_SUCCESS: 'monitoringV2/DELETE_SUCCESS',
  DELETE_FAILED: 'monitoringV2/DELETE_FAILED',
  PUT: 'monitoringV2/PUT',
  PUT_SUCCESS: 'monitoringV2/PUT_SUCCESS',
  PUT_FAILED: 'monitoringV2/PUT_FAILED',
  RESET: 'monitoringV2/RESET',
};

const initialState = {
  loading: false,
  loadingIds: [],
  error: null,
  data: [],
};

export default function monitoringV2Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, loading: true };
    case actionTypes.GET_FAILED:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.GET_SUCCESS:
      return { ...state, loading: false, data: action.payload.data ?? [], error: null };
    case actionTypes.POST:
      return { ...state, loading: true };
    case actionTypes.POST_FAILED:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.POST_SUCCESS:
      return { ...state, loading: false, data: [...state.data, action.payload.data], error: null };
    case actionTypes.DELETE:
      return { ...state, loading: true };
    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data.filter(item => item.id !== action.payload.listId)],
        error: null,
      };
    case actionTypes.DELETE_FAILED:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.PUT:
      return { ...state, loading: true, loadingIds: [...state.loadingIds, action.payload.athleteId] };
    case actionTypes.PUT_FAILED:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        loadingIds: state.loadingIds.filter(id => id !== action.payload.athleteId),
      };
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
}

let _lastFiltersGetList = '';
let _lastDateGetList = null;
export const getList = (filters, onSuccess) => async dispatch => {
  const queryString = stringifyFilters(filters === undefined || filters === null ? {} : filters);
  const now = new Date();
  if (_lastFiltersGetList !== queryString || _lastDateGetList == null || (now.getTime() - _lastDateGetList.getTime()) > 2000) {
    _lastFiltersGetList = queryString;
    _lastDateGetList = now;
  } else {
    //prevent multiple calls
    return;
  }

  dispatch({ type: actionTypes.GET });
  const url = Endpoints.monitoring;
  try {
    const { data } = await axios.get(`${url}${queryString}`);

    const isDataString = typeof data === 'string' || typeof data.results === 'string';

    dispatch({
      type: actionTypes.GET_SUCCESS,
      payload: {
        data: isDataString ? [] : data.results,
        hasNext: data.next ? true : false,
        hasPrevious: data.previous ? true : false,
        count: data.count,
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_FAILED,
        payload: { error: 'erros.text26' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_FAILED,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else if (response?.status === 402) {
      dispatch({
        type: actionTypes.GET_FAILED,
        payload: { error: ErrorMessages.excededPlanLimit },
      });
    } else {
      dispatch({
        type: actionTypes.GET_FAILED,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const addMonitor = async (athleteId, listId = null) => {
  try {
    const { data } = await axios.post(`${Endpoints.athletes}/${athleteId}/monitor`, { "group_list": listId });
    return data;
  } catch (err) {
    return false;
  }
};

export const deleteMonitor = async (athleteId, listId) => {
  try {
    const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}/monitor?group_list=${listId}`);
    if (data?.results?.length > 0) {
      await axios.delete(`${Endpoints.athletes}/${athleteId}/monitor/${data.results[0].id}`);
      return true;
    }
  } catch (err) {
  }
  return false;
};

export const createList = (userId, athlete, listData) => async dispatch => {
  dispatch({ type: actionTypes.POST });

  const url = Endpoints.monitoring;

  try {
    const { data } = await axios.post(`${url}`, listData);
    const monitoring = await addMonitor(athlete.id, data.id);
    if (monitoring && athlete.lists) {
      athlete.lists.push(data.id);
    }
    data.owner = userId;

    dispatch({
      type: actionTypes.POST_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    const { response } = err;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.POST_FAILED,
        payload: { error: 'erros.text27' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.POST_FAILED,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else if (response?.status === 402) {
      dispatch({
        type: actionTypes.POST_FAILED,
        payload: { error: ErrorMessages.excededPlanLimit },
      });
    } else {
      dispatch({
        type: actionTypes.POST_FAILED,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

export const deleteList = listId => async dispatch => {
  dispatch({ type: actionTypes.DELETE });
  const url = Endpoints.monitoring;
  try {
    await axios.delete(`${url}/${listId}`);
    dispatch({
      type: actionTypes.DELETE_SUCCESS,
      payload: { listId },
    });
  } catch (err) {
    const { response } = err;
    if (response?.status === 404) {
      dispatch({
        type: actionTypes.DELETE_FAILED,
        payload: { error: 'erros.text27' },
      });
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.DELETE_FAILED,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
    } else if (response?.status === 402) {
      dispatch({
        type: actionTypes.DELETE_FAILED,
        payload: { error: ErrorMessages.excededPlanLimit },
      });
    } else {
      dispatch({
        type: actionTypes.DELETE_FAILED,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
    }
  }
};

// export const changeList = (newLists, athleteId) => async dispatch => {
//   dispatch({
//     type: actionTypes.PUT,
//     payload: { athleteId },
//   });

//   const url = Endpoints.monitoring;
//   try {
//     let listPromises = [];
//     newLists.forEach(list => {
//       const p = axios.put(`${url}/${list.id}`, list);
//       listPromises.push(p);
//     });
//     await Promise.all(listPromises);
//     dispatch(
//       getList(null, async () => {
//         //todos esses dispatchs abaixo não fazem nada com o axios, é somente para atualizar o action que está acontecendo nas telas
//         const { data } = await axios.get(`${Endpoints.athletes}/${athleteId}`);
//         dispatch(updateListsV2(data.lists, athleteId));
//         dispatch(updateLists(data.lists, athleteId));
//         dispatch(updateCastLists(data.lists, athleteId));
//         dispatch(updateLoanedLists(data.lists, athleteId));
//         dispatch({
//           type: actionTypes.PUT_SUCCESS,
//           payload: { athleteId },
//         });
//       })
//     );
//   } catch (err) {
//     const { response } = err;
//     if (response?.status === 404) {
//       dispatch({
//         type: actionTypes.PUT_FAILED,
//         payload: { error: 'erros.text28' },
//       });
//     } else if (response?.status === 401) {
//       dispatch({
//         type: actionTypes.PUT_FAILED,
//         payload: { error: ErrorMessages.defaultCredentialsError },
//       });
//     } else if (response?.status === 402) {
//       dispatch({
//         type: actionTypes.PUT_FAILED,
//         payload: { error: ErrorMessages.excededPlanLimit },
//       });
//     } else {
//       dispatch({
//         type: actionTypes.PUT_FAILED,
//         payload: { error: ErrorMessages.serviceUnavailable },
//       });
//     }
//   }
// };

// export const changeDefault = (athleteId, isMonitoring) => async dispatch => {
//   const url = Endpoints.monitoring;

//   try {
//     let lists = null;

//     const { data } = await axios.get(`${url}`);
//     lists = data.results;

//     let defaultList = lists.find(list => list.name === '_DEFAULT_');
//     let newLists = [];

//     let newAthletes = null;

//     if (isMonitoring) {
//       lists.forEach(list => {
//         if (list.athletes.some(athlete => athlete?.athlete?.id === athleteId)) {
//           newAthletes = list.athletes
//             .filter(athlete => athlete?.athlete?.id !== athleteId)
//             .map(athlete => athlete?.athlete?.id);
//           newLists.push({ ...list, athletes: newAthletes });
//         }
//       });
//     } else {
//       newAthletes = [...defaultList.athletes.map(athlete => athlete?.athlete?.id), athleteId];

//       defaultList.athletes = newAthletes;

//       newLists = [defaultList];
//     }

//     dispatch(changeList(newLists, athleteId));
//   } catch (err) {
//     const { response } = err;

//     if (response?.status === 404) {
//       dispatch({
//         type: actionTypes.POST_FAILED,
//         payload: { error: 'erros.text29' },
//       });
//     } else if (response?.status === 401) {
//       dispatch({
//         type: actionTypes.POST_FAILED,
//         payload: { error: ErrorMessages.defaultCredentialsError },
//       });
//     } else if (response?.status === 402) {
//       dispatch({
//         type: actionTypes.POST_FAILED,
//         payload: { error: ErrorMessages.excededPlanLimit },
//       });
//     } else {
//       dispatch({
//         type: actionTypes.POST_FAILED,
//         payload: { error: ErrorMessages.serviceUnavailable },
//       });
//     }
//   }
// };

export const resetLists = () => async dispatch => {
  dispatch({
    type: actionTypes.RESET,
  });
};
