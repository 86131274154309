// hooks
import React, { useState } from 'react';
import useChats from './hooks/useChats';
import useMessages from './hooks/useMessages';
import useFilters from './hooks/useFilters';

// scss
import styles from './Messages.module.scss';

// components
import MessagesMenu from './Menu/Menu';
import ChatContent from './Content/Content';

export default function Messages() {
  const [sidebarLoading, setSidebarLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(true);
  const { filters, handleFilterChange } = useFilters();
  const { chats, selectedChat, handleSelectChat, repository } = useChats(filters, setSidebarLoading);
  const { submitMessage, deleteMessages, nextMessagesPage, messages, messageGroups, loggedUser, error } = useMessages(
    repository,
    selectedChat,
    setChatLoading
  );

  return (
    <div className={styles.container}>
      <MessagesMenu selectedRepository={repository} handleSelectChat={handleSelectChat} />
      <ChatContent
        repository={repository}
        chats={chats}
        messages={messages}
        selectedChat={selectedChat}
        messageGroups={messageGroups}
        submitMessage={submitMessage}
        deleteMessages={deleteMessages}
        nextMessagesPage={nextMessagesPage}
        handleFilterChange={handleFilterChange}
        handleSelectChat={handleSelectChat}
        filters={filters}
        user={loggedUser}
        sidebarLoading={sidebarLoading}
        chatLoading={chatLoading}
        error={error}
      />
    </div>
  );
}
