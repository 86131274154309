/*eslint-disable eqeqeq*/
import React, { useState, useEffect, useRef } from 'react';
import CustomCollapse from './CustomCollapse/CustomCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash/lang';
import styles from './Filter.module.scss';
import { Card, Spinner, Menu, Calendar, Button, InputApply } from 'components/template';
import { getCountries } from 'store/ducks/countries';
import { positionOptions, checkboxesAdvancedSearch, radioAdvancedSearch, brazilianStates, getPreferentialFootWithLabel } from '../../../shared/mocks';
import CheckboxContainer from './CheckboxContainer/CheckboxContainer';
import CheckboxContainerLists from './CheckboxContainerLists/CheckboxContainerLists';
import RadioContainer from './RadioContainer/RadioContainer';
import { MinMaxDate, MinMaxSeason } from './CustomInputs/CustomInputs';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom';
import { MdCalendarMonth } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';

export default function Filter({
  setQueryFilters,
  onSetQueryFilters,
  activeFilters,
  countries,
  setCountries,
  overlay,
  autoSearch,
  className,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const countriesSelector = useSelector(state => state.countries.countries);

  const initialFilters = {
    keyword: '',
    profile: [],
    contract: [],
    contractEnds: null,
    position: [],
    nationalities: [],
    gender: '',
    watched: false,
    lists: '',
    seasonMatches: {
      season: '',
      min: '',
      max: '',
    },
    seasonGoals: {
      season: '',
      min: '',
      max: '',
    },
    cbfCode: '',
    birthyear: {
      min: '',
      max: '',
    },
    division: [],
    country: '',
    state: '',
  };

  const mounted = useRef(false);
  const mountedRedirect = useRef(false);

  const [filters, setFilters] = useState(initialFilters);

  const location = useLocation();

  useEffect(() => {
    if (!mountedRedirect.current) {
      mountedRedirect.current = true;
    } else {
      const filtersStorage = getFiltersStorage();
      onSetFilters({ ...filters, ...filtersStorage });
    }
  }, [sessionStorage.redirect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filtersFromActive = { ...initialFilters, ...activeFilters };

    if (!isEqual(filtersFromActive, filters)) {
      setFilters(filtersFromActive);
    }
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countriesSelector.data.length > 0 && countries.length <= 0) {
      setCountries(countriesSelector.data);
    }
  }, [countriesSelector.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countriesSelector.data.length === 0) {
      dispatch(getCountries());
    }
  }, [dispatch, getCountries, countriesSelector.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      const filtersStorage = getFiltersStorage();

      if (filtersStorage) {
        const filtersFromStorage = { ...initialFilters, ...filtersStorage, isApplyButton: true };
        onSetFilters(filtersFromStorage);
      }
    } else {
      if (isEqual(filters, initialFilters)) {
        removeFiltersStorage();
      } else {
        saveFiltersStorage(filters);
      }

      if (autoSearch || (filters.isApplyButton ?? false)) {
        setQueryFilters(queryFiltersParser(filters));
      }
    }
  }, [setQueryFilters, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFiltersStorage = () => {
    return JSON.parse(sessionStorage.getItem('advanced_search'));
  };

  const saveFiltersStorage = filters => {
    sessionStorage.setItem('advanced_search', JSON.stringify(filters));
  };

  const removeFiltersStorage = () => {
    sessionStorage.removeItem('advanced_search');
  };

  const onSetFilters = newFilters => {
    const isApplyButton = newFilters.isApplyButton ?? false;
    if (isApplyButton || !isEqual(newFilters, filters)) {
      setFilters(newFilters);
      if (autoSearch || isApplyButton) {
        onSetQueryFilters({ ...newFilters });
      }
    }
  };

  const professionalFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'atletas-profissionais') {
        return true;
      } else if (arr[0] === 'atletas-amadores') {
        return false;
      }
    }

    return null;
  };

  const contractFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'com-contrato') {
        return true;
      } else if (arr[0] === 'sem-contrato') {
        return false;
      }
    }

    return null;
  };

  const queryFiltersParser = filters => {
    const params = {
      q: filters.keyword,
      custom_athletes: filters.custom_athletes,
      professional: professionalFilterParser(filters.profile.map(item => item)),
      has_contract: contractFilterParser(filters.contract.map(item => item)),
      contract_ends: filters.contractEnds,
      position: filters.position,
      nationalities: filters.nationalities,
      female: filters.gender == 'female',
      season_matches: filters.seasonMatches.season,
      min_matches_in_season: filters.seasonMatches.min,
      max_matches_in_season: filters.seasonMatches.max,
      season_goals: filters.seasonGoals.season,
      min_goals_in_season: filters.seasonGoals.min,
      max_goals_in_season: filters.seasonGoals.max,
      min_birthyear: filters.birthyear.min,
      max_birthyear: filters.birthyear.max,
      cbf_code: filters.cbfCode,
      foot: filters.foot,
      division: filters.division,
      team_country: filters.country,
      team_state: filters.state,
      watched: filters.watched,
      lists: filters.lists,
    };

    return params;
  };

  let content;
  content = (
    <div>
      {overlay && <div className={styles.overlay} />}
      <Card borderedGrey className={className}>
        <Scrollbars
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '10px',
                height: '160px !important',
                borderRadius: '5px',
                backgroundColor: '#D7D6D2',
                right: '5px',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div {...props} style={{ ...style, borderRadius: '5px', backgroundColor: '#D7D6D2' }} />
          )}
        >
          <CustomCollapse openedDefault title={t('filters.key')}>
            {location.pathname.includes('/busca-avancada') ? (
              <>
                <div className={styles.filterInnerContainer}>
                  <input
                    className={styles.filterInput}
                    onChange={e => {
                      const searchText = e.target.value.toLowerCase();
                      onSetFilters({ ...filters, keyword: searchText, isApplyButton: false });
                    }}
                    onKeyUp={e => {
                      if (e.keyCode === 13) {
                        onSetFilters({ ...filters, isApplyButton: true });
                      }
                    }}
                  />
                  <div
                    className={styles.svgContainer}
                    onClick={() => onSetFilters({ ...filters, isApplyButton: true })}
                  >
                    <AiOutlineSearch />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.filterInnerContainer2}>
                  <input
                    value={filters.keyword}
                    className={styles.filterInput}
                    onChange={e => onSetFilters({ ...filters, keyword: e.target.value, isApplyButton: false })}
                  />
                  <div
                    className={styles.svgContainer2}
                    onClick={() => onSetFilters({ ...filters, isApplyButton: true })}
                  >
                    <AiOutlineSearch />
                    <Button
                      className={styles.applyButton}
                      onClick={() => onSetFilters({ ...filters, isApplyButton: true })}
                      rounded
                      color="green"
                      smaller
                    >
                      {t('proj.applyFilters')}
                    </Button>
                  </div>
                </div>
                <Button
                  className={styles.clearButton}
                  onClick={() => setFilters(initialFilters)}
                  rounded
                  color="green"
                  smaller
                >
                  {t('filters.clean_filters')}
                </Button>
              </>
            )}
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.custom_athletes')}>
            <RadioContainer
              setFilter={value => onSetFilters({ ...filters, custom_athletes: value, isApplyButton: true })}
              value={filters.custom_athletes || 'normal'}
              options={
                user.organization != null
                  ? radioAdvancedSearch.custom_athletes.map(item => ({
                    label: t(`radioAdvancedSearchCustomAthlete.${item.value}`),
                    value: item.value,
                  }))
                  : radioAdvancedSearch.custom_athletes2.map(item => ({
                    label: t(`radioAdvancedSearchCustomAthlete.${item.value}`),
                    value: item.value,
                  }))
              }
              name={t('filters.custom_athletes')}
            />
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.gender')}>
            <RadioContainer
              setFilter={value => onSetFilters({ ...filters, gender: value, isApplyButton: true })}
              value={filters.gender || 'male'}
              options={radioAdvancedSearch.gender.map(item => ({
                label: t(`radioAdvancedSearch.${item.value}`),
                value: item.value,
              }))}
              name={t('filters.gen')}
            />
          </CustomCollapse>
          <CheckboxContainerLists
            setFilter={(listsIds, watched) =>
              onSetFilters({ ...filters, lists: listsIds, watched: watched, isApplyButton: true })
            }
            listsIds={filters.lists || ''}
            watched={filters.watched || false}
          />
          <CustomCollapse openedDefault title={t('filters.profile')}>
            <CheckboxContainer
              setFilter={value => onSetFilters({ ...filters, profile: value, isApplyButton: true })}
              value={filters.profile || null}
              checkboxes={checkboxesAdvancedSearch.profile.map(item => ({
                label: t(`profileOptions.${item.value}`),
                value: item.value,
              }))}
            />
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.contract')}>
            <CheckboxContainer
              setFilter={value => onSetFilters({ ...filters, contract: value, isApplyButton: true })}
              value={filters.contract || null}
              checkboxes={checkboxesAdvancedSearch.contract.map(item => ({
                label: t(`contractOptions.${item.value}`),
                value: item.value,
              }))}
            />
            <div className={styles.contractDate}>
              <span>{t('filters.contract_until')}</span>
              <div className={styles.calendar}>
                <Calendar
                  value={filters.contractEnds}
                  setValue={value => onSetFilters({ ...filters, contractEnds: value, isApplyButton: true })}
                />
                <div className={styles.calendarIcon}>
                  <MdCalendarMonth />
                </div>
              </div>
            </div>
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.pos')}>
            <CheckboxContainer
              setFilter={value => onSetFilters({ ...filters, position: value, isApplyButton: true })}
              value={filters.position || null}
              checkboxes={positionOptions.map(item => ({
                label: t(`positionOptions.${item.value}`),
                value: item.label,
              }))}
            />
          </CustomCollapse>
          {countriesSelector.loading ? (
            <CustomCollapse openedDefault title={t('filters.nation')} key="countriesloading">
              <Spinner width="30" height="30" className={styles.spinner} />
            </CustomCollapse>
          ) : (
            <CustomCollapse openedDefault title={t('filters.nation')} key="countriesloaded">
              <CheckboxContainer
                setFilter={value => onSetFilters({ ...filters, nationalities: value, isApplyButton: true })}
                value={filters.nationalities || null}
                checkboxes={countries.map(country => ({
                  label: country.name === 'Madagáscar' ? 'Madagascar' : country.name,
                  value: country.code,
                }))}
              />
            </CustomCollapse>
          )}
          <CustomCollapse customStyle={styles.collapseWithMessage} openedDefault title={t('filters.games')}>
            <div className={styles.menuMinMaxContainer}>
              <MinMaxSeason
                setFilter={value => onSetFilters({ ...filters, seasonMatches: value, isApplyButton: true })}
                onClear={() => onSetFilters({ ...filters, seasonMatches: {
                  season: '',
                  min: '',
                  max: '',
                }, isApplyButton: true })}
                value={filters.seasonMatches}
              />
            </div>
          </CustomCollapse>
          <CustomCollapse customStyle={styles.collapseWithMessage}  openedDefault title={t('filters.gols')}>
            <div className={styles.menuMinMaxContainer}>
              <MinMaxSeason
                setFilter={value => onSetFilters({ ...filters, seasonGoals: value, isApplyButton: true })}
                onClear={() => onSetFilters({ ...filters, seasonGoals: {
                  season: '',
                  min: '',
                  max: '',
                } })}
                value={filters.seasonGoals}
              />
            </div>
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('athlete_card.foot')}>
            <Menu
              className={styles.largeSelect}
              title={t('athlete_card.foot')}
              placeholder={t('athlete_card.foot')}
              options={getPreferentialFootWithLabel(t)}
              value={filters.foot}
              setValue={value => onSetFilters({ ...filters, foot: value, isApplyButton: true })}
              onClear={() => onSetFilters({ ...filters, foot: '' })}
              allowEmpty
            />
          </CustomCollapse>
          {/* <CustomCollapse openedDefault title='Atleta Monitorado'>
                            <CheckboxContainer checkboxes={checkboxesAdvancedSearch.monitoredAthlete} />
                        </CustomCollapse> */}
          <CustomCollapse openedDefault title={t('filters.cbf_num')}>
            <div className={styles.inputNumCBF}>
              <InputApply
                value={filters.cbfCode}
                type="number"
                onClick={value => onSetFilters({ ...filters, cbfCode: value, isApplyButton: true })}
              />
            </div>
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.birt_year')}>
            {/* <div className={styles.MinMaxDate}> */}
            <div className={styles.minMaxDateChildren}>
              <MinMaxDate
                from
                title={t('slider_menu.in')}
                titlePlaceholder={(filters?.birthyear?.min ?? '').toString().length > 0 ? filters.birthyear.min : '---'}
                setFilter={values =>
                  onSetFilters({
                    ...filters,
                    birthyear: {
                      ...filters.birthyear,
                      min: values.min 
                    },
                    isApplyButton: true,
                  })
                }
                onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, min: '' } })}
                valueMin={filters.birthyear.min}
                valueMax={filters.birthyear.max}
              />
            </div>
            <div className={styles.minMaxDateChildren}>
              <MinMaxDate
                to
                title={t('slider_menu.e')}
                titlePlaceholder={(filters?.birthyear?.max ?? '').toString().length > 0 ? filters.birthyear.max : '---'}
                setFilter={values =>
                  onSetFilters({
                    ...filters,
                    birthyear: {
                      ...filters.birthyear,
                      max: values.max 
                    },
                    isApplyButton: true,
                  })
                }
                onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, max: '' } })}
                valueMin={filters.birthyear.min}
                valueMax={filters.birthyear.max}
              />
            </div>
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.division_ope')}>
            <CheckboxContainer
              setFilter={value => onSetFilters({ ...filters, division: value, isApplyButton: true })}
              value={filters.division || null}
              checkboxes={checkboxesAdvancedSearch.newDivision.map(item => ({
                label: t(`newDivisionOptions.${item.id}`),
                value: item.value,
              }))}
            />
          </CustomCollapse>
          <CustomCollapse openedDefault title={t('filters.country_ope')}>
            {countriesSelector.loading ? (
              <Spinner width="30" height="30" className={styles.spinner} />
            ) : (
              <Menu
                className={styles.largeSelect}
                title={t('filters.country_ope')}
                placeholder={filters.country || t('home.text5')}
                options={countries.map(country => ({ label: country.name, value: country.code }))}
                value={filters.country}
                setValue={value => onSetFilters({ ...filters, country: value, isApplyButton: true })}
                onClear={() => onSetFilters({ ...filters, country: '' })}
                allowEmpty
              />
            )}
          </CustomCollapse>
          {filters.country === 'BR' || filters.country === '' ? (
            <CustomCollapse openedDefault title={t('filters.state_ope')}>
              <Menu
                className={styles.largeSelect}
                title={t('filters.state_ope')}
                placeholder={filters.state || t('home.text5')}
                options={brazilianStates}
                value={filters.state}
                setValue={value => onSetFilters({ ...filters, state: value, isApplyButton: true })}
                onClear={() => onSetFilters({ ...filters, state: '' })}
                allowEmpty
              />
            </CustomCollapse>
          ) : (
            <></>
          )}
          {/* <CustomCollapse openedDefault title='Possui passaporte europeu'>
                            <CheckboxContainer checkboxes={checkboxesAdvancedSearch.europeanPassport} />
                        </CustomCollapse> */}
        </Scrollbars>
      </Card>
    </div>
  );

  return <div className={styles.container}>{content}</div>;
}
