/*eslint-disable eqeqeq*/
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Creators } from 'store/ducks/projects';
import { Dialog, Input, Button } from 'components/template';
import styles from './ProjectDialog.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Creators as layoutCreators } from 'store/ducks/layout';

const ProjectDialog = ({ projectDialog, setProjectDialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = {
    name: '',
    photo: null,
    photo64: null,
  };
  const [modifiedProject, setModifiedProject] = useState({ ...(projectDialog?.project ?? project) });
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [deleteProject, setDeleteProject] = useState(false);
  const loggedUser = useSelector(state => state.user.data);
  const loggedUserPermission =
    (modifiedProject.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ??
    'Visualizador';

  useEffect(() => {
    setModifiedProject({ ...(projectDialog?.project ?? project) });
  }, [projectDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = updatePage => {
    if (deleteProject) {
      setDeleteProject(false);
    } else {
      setProjectDialog({ ...projectDialog, isOpen: false, updatePage: typeof updatePage == 'boolean' && updatePage });
      setModifiedProject(project);
    }
  };

  const onDelete = () => {
    setSaveButtonDisable(true);
    Creators.deleteProject(modifiedProject).then(result => {
      setSaveButtonDisable(false);
      if (result.error !== undefined) {
        alert(result.error);
      } else {
        setDeleteProject(false);
        setProjectDialog({ ...projectDialog, isOpen: false, updatePage: true });
        setModifiedProject(project);
      }
    });
  };

  const saveProject = () => {
    setSaveButtonDisable(true);
    if (projectDialog?.project?.id) {
      Creators.updateProject(modifiedProject).then(result => {
        setSaveButtonDisable(false);
        if (result.error !== undefined) {
          alert(result.error);
        } else {
          setProjectDialog({ isOpen: false, project: { ...modifiedProject }, updatePage: true });
          setModifiedProject(project);
        }
      });
    } else {
      Creators.createProject(modifiedProject).then(result => {
        setSaveButtonDisable(false);
        if (result.error !== undefined) {
          if (result.status === 402) {
            dispatch(layoutCreators.showExcededPlanLimit());
          } else {
            alert(result.error);
          }
        } else if (result.data !== undefined) {
          onClose(true);
        }
      });
    }
  };

  const handleInputChange = e => {
    setModifiedProject({ ...modifiedProject, [e.target.name]: e.target.value });
    e.persist();
  };

  const inputFileRef = useRef(null);
  const triggerInputFile = () => inputFileRef.current.click();

  const readFile = file => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.addEventListener('load', function(e) {
        resolve(e.target.result);
      });

      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = e => {
    onUploadFile(e);
  };

  const onUploadFile = async e => {
    const photo = e.target.files[0];
    const photo64 = await readFile(photo);
    setModifiedProject(f => ({ ...f, photo: photo, photo64: photo64 }));
  };

  let projectImage = (
    <div
      className={styles.projectPhoto}
      style={{ backgroundImage: `url(${modifiedProject.photo64 ?? modifiedProject.photo})` }}
    />
  );

  let content = (
    <div className={styles.content}>
      <div className={styles.formGroup}>
        <label>{t('user.text5')}:</label>
        <span>
          <Input
            rounded
            name="name"
            onChange={e => handleInputChange(e)}
            inputStyle={{ width: 300 }}
            value={modifiedProject?.name ?? ''}
          />
        </span>
      </div>
      <div className={styles.formGroup}>
        <label>{t('report_pro.image')}:</label>
        <span>
          {modifiedProject.photo && projectImage}
          <input
            type="file"
            hidden
            ref={inputFileRef}
            accept="image/webp,image/png,image/jpeg"
            onChange={handleFileUpload}
          />
          <Button color="link" noShadow smaller onClick={triggerInputFile} style={{ marginLeft: '0' }}>
            {modifiedProject.photo ? t('rest.box16') : t('rest.box17')}
          </Button>
        </span>
      </div>
      <div className={styles.formGroup}>
        <label></label>
        <span>
          <small>{t('proj.modalUploadImagem')}: 360x150px</small>
        </span>
      </div>
    </div>
  );

  return (
    <Dialog
      isOpen={projectDialog?.isOpen}
      onClose={onClose}
      mainTitle={`${
        deleteProject ? t('buttons.excluir') : modifiedProject.id ? t('report_pro.edit') : t('proj.create')
      } ${t('proj.proj')}`}
      btnFloatLeft={
        !deleteProject &&
        (loggedUserPermission == 'Proprietário' || loggedUserPermission == 'Administrador') &&
        modifiedProject.id
          ? t('buttons.excluir')
          : ''
      }
      btnFloatLeftClick={() => setDeleteProject(true)}
      btnFloatLeftDisabled={saveButtonDisable}
      btnLeft={t('buttons.cancel')}
      btnLeftClick={onClose}
      btnRight={deleteProject ? t('buttons.excluir') : t('buttons.save')}
      btnRightColor={deleteProject ? 'danger' : 'green'}
      btnRightClick={deleteProject ? onDelete : saveProject}
      btnRightDisabled={saveButtonDisable}
      className={{
        container: styles.dialogContainer,
      }}
    >
      {deleteProject ? t('proj.confirmationDeleteModal') : content}
    </Dialog>
  );
};

export default ProjectDialog;

ProjectDialog.defaultProps = {
  projectDialog: PropTypes.bool.isRequired,
  setProjectDialog: PropTypes.func.isRequired,
};
