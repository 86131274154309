import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AthleteList.module.scss';
import { Table } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import { useTranslation } from 'react-i18next';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import moment from 'moment';
import ButtonOffer from './ButtonOffer';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import Variables from 'variables';
import { Dialog } from 'components/template';
import { IoMdWarning } from "react-icons/io";

export default function AthleteList({transferMarket, allAthletes}) {
  const { t } = useTranslation();
  const [selectedOfferStatus, _setSelectedOfferStatus] = useState('offered');
  const [dialogConfirmation, setDialogConfirmation] = useState({ save: false, offer_id: null });
  const isAdOwner = transferMarket.is_ad_owner;
  const athletesPerStatus = {
    offered: [],
    discarded: [],
    accepted: [],
    changed: [],
  };
  const [acceptOffer, setAcceptOffer] = useState(false);

  const disableRow = (i, offer) => {
    let table = document.getElementById('athletes-table');
            const rows = table.getElementsByTagName('tr');
            if(rows.length > 1){
              if(selectedOfferStatus !== offer.status){
                rows[i + 1].className = styles.trDisabled
              }else {
                rows[i + 1].className = ''
              }
            }
  }

  const setSelectedOfferStatus = newStatus => {
    //eu não sei pq só sei que tem que ser assim para atualizar o estado dos botões em tela, qlq coisa fala com o jordan walke.
    _setSelectedOfferStatus('changed');
    setTimeout(() => _setSelectedOfferStatus(newStatus), 0);
  }

  if (isAdOwner) {
    for (const athlete of allAthletes) {
      const offersLength = athlete?.offers?.length ?? 0;
      if (offersLength > 0) {
        const offerStatus = athlete.offers[0].status;
        athletesPerStatus[offerStatus].push(athlete);
      }
    }
  }  
  let athletes = isAdOwner ? athletesPerStatus[selectedOfferStatus] : allAthletes;  
  const mainNationalities =  transferMarket.nationalities.map(n => n.code);
  athletes = athletes.filter(athlete => 
    athlete.nationalities.some(nat => mainNationalities.includes(nat.code))
  );

  let data = athletes?.map((row, i) => {
    const athlete = <AthleteRow name={row.fullname} nickname={row.nickname} img={row.photo} id={row.id} />;
    const position = row.position2 ?? row.position;
    const age = row.age;
    const actualTeam = row.current_team?.nickname ? (
      <TeamRow 
        contractDate={row.contract_info?.current_contract_end ? moment(row.contract_info.current_contract_end).format('DD/MM/YYYY') : null} 
        name={row.current_team?.nickname} 
        id={row.current_team?.id} 
        img={row.current_team?.emblem} 
      />
    ) : null;
    const offeredTeam = isAdOwner ? (
      <TeamRow 
        name={row.offers[0]?.offering?.name ?? row.offers[0]?.offering?.nickname} 
        id={(row.offers[0]?.offering)?.id} 
        img={(row.offers[0]?.offering)?.emblem}
        type={(row.offers[0]?.offering)?.type}
      />
    ) : null
    const transferTeam = !isAdOwner && row.src_team?.id ? (
      <TeamRow 
        contractDate={row.contract_info?.src_contract_end ? moment(row.contract_info.src_contract_end).format('DD/MM/YYYY') : null} 
        name={row.src_team?.name} 
        id={row.src_team?.id} 
        img={row.src_team?.emblem}
        type={row.src_team?.type}
      />
    ) : null;
    const nationalities = (
      <span className={styles.flag}>
        <NationalitiesRow nationalities={row.nationalities} athleteId={row.id} />
      </span>
    );
    const buttons = <ButtonOffer 
        selectedOfferStatus={selectedOfferStatus}
        transferMarket={transferMarket} 
        athlete={row} 
        onOwnerUpdateStatus={offer => {
          offer.offering = row.offers[0]?.offering ?? offer.offering;
          row.offers = [offer];
          disableRow(i, offer);
        }}
        dialogConfirmation={dialogConfirmation}
        setDialogConfirmation={setDialogConfirmation}
        acceptOffer={acceptOffer}
      />;

    return [
      athlete,
      actualTeam,
      age,
      position,
      nationalities,
      isAdOwner ? offeredTeam : transferTeam,
      buttons
    ]; 
  }) ?? [];

  const confirmationDialogOnClose = save => {
    setDialogConfirmation({ save: save, offer_id: save ? dialogConfirmation.offer_id : null });
    if (save) {
      setAcceptOffer(true);
    } else {
      setAcceptOffer(false);
    }
  }

  return (  
    <div className={styles.container}>
      {isAdOwner ? (
        <>
          <h3 style={{margin: 0}}>{t('transferMarket.atlheteOffers')}</h3>
          <NavigationBar 
            className={styles.navigationBar}
            items={[
              {
                label: <div className={styles.offeredStatusItem}>{t('transferMarket.athletesOffered')}<span>{athletesPerStatus['offered'].length}</span></div>,
                onClick: () => setSelectedOfferStatus('offered'),
                isActive: selectedOfferStatus === 'offered',
              },
              {
                label: <div className={styles.offeredStatusItem}>{t('transferMarket.athletesInConversation')}<span>{athletesPerStatus['accepted'].length}</span></div>,
                onClick: () => setSelectedOfferStatus('accepted'),
                isActive: selectedOfferStatus === 'accepted',
              },
              {
                label: <div className={styles.offeredStatusItem}>
                  {t('transferMarket.discardedAthletes')}
                  <span style={{ backgroundColor: Variables.gray4 }}>{athletesPerStatus['discarded'].length}</span>
                </div>,
                onClick: () => setSelectedOfferStatus('discarded'),
                isActive: selectedOfferStatus === 'discarded',
              },
            ]} 
          />
        </>
      ) : (
        <h3>{
          t('transferMarket.athleteList.youHave1')+
          data.length +
          t('transferMarket.athleteList.youHave2')
        }</h3>
      )}
      { data.length > 0 &&
        <Table
          id='athletes-table'
          flat
          theads={[              
            t('table.atleta'),
            t('agencies.text2')+'/'+t('myClub.professionalCast.contractUntil'),
            t('table.idade'),
            t('table.posicao'),
            t('filters.nation'),
            isAdOwner ? t('transferMarket.offeredBy') : t('myClub.professionalCast.cedingClub')+'/'+t('myClub.professionalCast.contractUntil'),
            ''
          ]}
          activeTab={selectedOfferStatus}
          data={data}
          className={`${styles.athleteTable} ${isAdOwner ? styles.athleteTableAdOwner : ''}`}
        />
      }
      <Dialog
        isOpen={dialogConfirmation.offer_id != null}
        onClose={() => confirmationDialogOnClose(false)}
        mainTitle={(
          <div className={styles.dialogConfirmTitle}>
            <IoMdWarning /> 
            {t('transferMarket.attention')}
          </div>
        )}
        className={{ body: styles.dialogConfirmBody }}
        btnLeft={t('dialog_content.noCancel')}
        btnLeftClick={() => confirmationDialogOnClose(false)}
        btnRight={t('dialog_content.yesProceed')}
        btnRightClick={() => confirmationDialogOnClose(true)}
        containerClassName={styles.dialogContainer}
      >
        {t('transferMarket.dialogMessage')}
        <br/><br/>
        <b>{t('transferMarket.wantToContinue')}</b>
      </Dialog>
    </div>
  );
}

AthleteList.defaultProps = {
  hasFilter: false,
};

AthleteList.propTypes = {
  hasFilter: PropTypes.bool,
};
