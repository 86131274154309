import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export default function SelectionButton({ items, selected, onClick }) {
  return <div className={styles.button}>
    {items.map(item => {
      return <div 
        key={`SelectionButton${item.value}`}
        className={item.value === selected ? styles.selected : ''}
        onClick={() => onClick(item.value)}
      >
        <span>{item.label}</span>
      </div>
    })}
  </div>
}


SelectionButton.defaultProps = {
  items: [],
  selected: null,
  onClick: null,
};

SelectionButton.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func,
};
