import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const HorizontalBarChart = (data) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      stacked: true,
      horizontal: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%', // Altura das barras        
      },
    },
    colors: ['#eb5757', '#f2c94c', '#27ae60', '#ddd'],
    dataLabels: {
      enabled: false,
      formatter: (val) => {
        return val.toFixed(1); // Formata os valores dos labels
      },
      style: {
        fontSize: '12px',
        colors: ['#fff'], // Cor do texto dos labels
      },
    },
    xaxis: {
      categories: [], 
      labels: {
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
            colors: ['#4a4a4a'], // Cor dos textos das labels do eixo Y
        },   
    },
      axisBorder: {
        show: false, // Oculta a linha do eixo X
      },
      axisTicks: {
        show: false, // Oculta os ticks do eixo X
      },
    },
    yaxis: {
        opposite: true,
        labels: {
            style: {
                fontSize: '12px',
                fontWeight: '400',
                colors: ['#4a4a4a'], // Cor dos textos das labels do eixo Y
            },   
        },
        axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'Left',
      offsetX: 0,
      offsetY: 0,
    },
    grid: {
      show: false, // Mostra a grade no fundo
      borderColor: '#90A4AE', // Cor da grade
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} Jogadores`,
      },
    },
  });

  const [series, setSeries] = useState([
    
  ]);

  useEffect(() => { 
    if(data?.data){
      const categories = Object.keys(data.data).filter(key => key !== 'total_count_athletes');
      const translatedCategories = categories.map(category => t('positionOptions.' + category));
      setOptions({...options, xaxis: {...options.xaxis, categories: translatedCategories}});
      const monitorar = [];
      const contratar = [];      
      const descartar = [];
      const semDirecionamento = [];

      categories.forEach((item) => {
        monitorar.push(data?.data[item]?.OBSERVAR || 0 + data?.data[item]?.MONITORAR || 0);
        contratar.push(data?.data[item]?.CONTRATAR || 0 + data?.data[item]?.NEGOCIAR || 0);        
        descartar.push(data?.data[item]?.DESCARTAR || 0);
        semDirecionamento.push(data?.data[item]?.[""] || 0);
      });

      const series = [
        {
          name: 'Descartar',
          data: descartar,
        },
        {
          name: 'Monitorar',
          data: monitorar,
        },
        {
          name: 'Contratar',
          data: contratar,
        },        
        {
          name: t('charts.noDirection'),
          data: semDirecionamento,
        },
      ];
      setSeries(series);
    }
  }, [data]);//eslint-disable-line

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default HorizontalBarChart;
