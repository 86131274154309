/*eslint-disable eqeqeq*/
import React from 'react';
import * as S from './styles';
import { IoIosSettings } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ProjectItem({ project, onEdit }) {
  const { t } = useTranslation();
  const loggedUser = useSelector(state => state.user.data);
  const loggedUserPermission =
    (project.users ?? []).find(projectUser => projectUser?.user?.id == loggedUser.id)?.permission ?? 'Visualizador';

  if (project?.users === undefined) {
    return '';
  }

  let users;
  if (project.users.length > 2) {
    users = (
      <>
        <S.Avatar img={project.users[0].user.photo} name={project.users[0].user.name} />
        <S.Avatar img={project.users[1].user.photo} name={project.users[1].user.name} />
        <S.AvatarNum name={`+ ${project.users.length - 2}`} />
      </>
    );
  } else {
    users = project.users.map(pu => <S.Avatar img={pu.user.photo} name={pu.user.name} />);
  }

  return (
    <S.Item>
      {loggedUserPermission !== 'Visualizador' && (
        <S.EditProject onClick={onEdit}>
          <IoIosSettings />
        </S.EditProject>
      )}
      <S.Link key={project.id} to={`/projetos/${project.id}/workflow`}>
        <S.ImageSection
          style={{ backgroundImage: `url(${project.photo})`, backgroundColor: project.photo === null ? '#DECCFF' : '' }}
        >
          <S.MembersList>{users}</S.MembersList>
        </S.ImageSection>
        <S.ProjectData>
          <S.ProjectName>{project.name}</S.ProjectName>
          <S.AthleteQuantity>
            {project.athletes_count} {t('club.box7')}{' '}
          </S.AthleteQuantity>
          <S.LastModified>
            {t('proj.ultimaAtualizacao')}
            {moment(project.lastmodified_at).format('DD/MM/YYYY')}
          </S.LastModified>
        </S.ProjectData>
      </S.Link>
    </S.Item>
  );
}
