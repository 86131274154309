import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './ReportEdition.module.scss';
import ReportAppBar from 'components/ReportAppBar/ReportAppBar';
import { getAthleteEvaluation } from 'store/ducks/evaluations';
import { Card, Table, Button, TextArea, Avatar, Input, Menu, Checkbox } from 'components/template';
import FieldList from 'pages/Athlete/Evaluation/FieldList/FieldList';
import CenteredLoader from 'components/template/CenteredLoader';
import ImageList from 'components/ImageList/ImageList';
import { FaLongArrowAltRight } from 'react-icons/fa';
import TeamRow from 'components/TeamRow/TeamRow';
import IcoTempo from 'assets/ico/ico-tempo.png';
import IcoJogos from 'assets/ico/ico-jogos.png';
import IcoTitular from 'assets/ico/ico-titular.png';
import IcoEntrou from 'assets/ico/ico-entrou.png';
import IcoSaiu from 'assets/ico/ico-saiu.png';
import IcoGols from 'assets/ico/ico-gols.png';
import IcoAssistencias from 'assets/ico/ico-assistencias.png';
import IcoAmarelos from 'assets/ico/ico-amarelos.png';
import IcoAmareloVermelho from 'assets/ico/ico-amarelo-vermelho.png';
import IcoVermelhos from 'assets/ico/ico-vermelhos.png';
import { getBase64 } from 'shared/utility';
import { useTranslation } from 'react-i18next';

let fileIndex = 0;
export default function ReportEdition({
  data,
  configuration,
  setConfiguration,
  setAthletesInfoData,
  athletesInfoData,
  athleteInfoData,
}) {
  const { t } = useTranslation();
  const [modifiedAthlete, setModifiedAthlete] = useState(athleteInfoData);
  const [imagesFileInputValue, setImagesFileInputValue] = useState([]);

  const [editingField, setEditingField] = useState({
    generalFeatures: false,
    opinion: false,
    background: false,
  });

  useEffect(() => {
    let athletesInfoDataCopy = [...athletesInfoData];

    athletesInfoDataCopy = athletesInfoDataCopy.map(athlete => {
      if (athlete.id === modifiedAthlete.id) {
        return modifiedAthlete;
      } else {
        return athlete;
      }
    });
    setAthletesInfoData(athletesInfoDataCopy);
  }, [modifiedAthlete, imagesFileInputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const rawData = data.athlete;
  const evaluationsData = data.evaluations;
  const historicalData = data.historical;
  const transfersData = data.transfers;
  const contractsData = data.contracts;

  const inputFileRef = useRef(null);

  const addFile = (arr, file) => {
    arr.push({
      id: fileIndex,
      title: '',
      file: file,
    });
    fileIndex++;

    setImagesFileInputValue(arr);
    setModifiedAthlete(f => ({ ...f, files: arr }));
  };

  const handleFileUpload = async e => {
    let copy = [...imagesFileInputValue];
    for (let val of e.target.files) {
      getBase64(val, result => addFile(copy, result));
    }
  };

  const triggerInputFile = () => inputFileRef.current.click();

  const handleInputChange = e => {
    e.persist();
    setModifiedAthlete({ ...modifiedAthlete, [e.target.name]: e.target.value });
  };

  if (rawData.agency && Object.keys(modifiedAthlete.negotiation).length === 0) {
    const newNegotiation = { agency: rawData.agency.name };
    if (rawData.agency.phone1?.number) {
      newNegotiation.agency_phone = `${rawData.agency.phone1.ddd ?? ''} ${rawData.agency.phone1.number}`;
    }
    if (rawData.agency.whatsapp?.number) {
      newNegotiation.agency_whatsapp = `${rawData.agency.whatsapp.ddd ?? ''} ${rawData.agency.whatsapp.number}`;
    }
    setModifiedAthlete({ ...modifiedAthlete, negotiation: newNegotiation });
  }

  const nationalities = rawData.nationalities?.length
    ? rawData.nationalities.map(item => <img src={item.flag} alt={`Bandeira do ${item.name}`} key={item.name} />)
    : '';

  const athleteData = (
    <Card softShadow className={styles.mainInfo}>
      <div className={styles.athletePhotoDiv}>
        <div className={styles.athletePhoto} style={{ backgroundImage: `url(${modifiedAthlete.photo})` }} />
      </div>

      <div className={styles.athleteInfo}>
        <div className={styles.generalData}>
          <div className={styles.leftContent}>
            <div className={styles.athleteName}>
              <p>{modifiedAthlete.nickname}</p>
              <span>{modifiedAthlete.fullname}</span>
            </div>
            <div className={styles.athleteAge}>
              <div className={styles.formGroup}>
                <label>{t('athlete_list.box4')}</label>
                <span>{t(`positionOptionsRaw.${modifiedAthlete.position}`)}</span>
                <label>{t('athlete_list.box3')}:</label>
                <span>
                  '{rawData.birthyear % 100} ({rawData.age} {t('athlete_card.idade')})
                </span>
                {nationalities}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.aditionalData}>
          <div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.birth')}</label>
              <span>{modifiedAthlete.birthdate && moment(modifiedAthlete.birthdate).format('DD/MM/YYYY')}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_list.box3')}:</label>
              <span>{modifiedAthlete.age} anos</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('club.text13')}:</label>
              <span>{modifiedAthlete.height ? modifiedAthlete.height + 'cm' : '-'}</span>
            </div>
          </div>
          <div>
            <div className={styles.formGroup}>
              <label>{t('athlete_list.box4')}:</label>
              <span>{t(`positionOptionsRaw.${modifiedAthlete.position}`)}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.foot')}</label>
              <span>{modifiedAthlete.foot}</span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('club.text14')}:</label>
              <span>{modifiedAthlete.weight ? modifiedAthlete.weight + 'kg' : '-'}</span>
            </div>
          </div>
          <div className={styles.clubInfo}>
            {rawData.current_team && (
              <div className={styles.formGroup}>
                <label>{t('agencies.text2')}:</label>
                <span className={styles.flags}>
                  <img
                    src={rawData.current_team?.country?.flag}
                    alt={`Bandeira do ${rawData.current_team?.country?.name}`}
                  />
                  {rawData.current_team?.emblem ? (
                    <img src={rawData.current_team?.emblem} alt={`Bandeira do ${rawData.current_team?.nickname}`} />
                  ) : (
                    <Avatar
                      img={rawData.current_team?.emblem}
                      name={rawData.current_team?.nickname}
                      className={styles.avatar}
                    />
                  )}
                  <span>{rawData.current_team?.nickname}</span>
                </span>
              </div>
            )}
            {rawData.contract_info?.current_contract_end && (
              <div className={styles.formGroup}>
                <label>{t('athlete_list.box6')}:</label>
                <span>{moment(rawData.contract_info?.current_contract_end).format('DD/MM/YYYY')}</span>
              </div>
            )}
          </div>
          <div className={styles.clubInfo}>
            {rawData.src_team && (
              <div className={styles.formGroup}>
                <label>{t('agencies.text4')}:</label>
                <span className={styles.flags}>
                  <img src={rawData.src_team?.country?.flag} alt={`Bandeira do ${rawData.src_team?.country?.name}`} />
                  {rawData.src_team?.emblem ? (
                    <img src={rawData.src_team?.emblem} alt={`Bandeira do ${rawData.src_team?.country?.nickname}`} />
                  ) : (
                    <Avatar
                      img={rawData.src_team?.emblem}
                      name={rawData.src_team?.nickname}
                      className={styles.avatar}
                    />
                  )}
                  <span>{rawData.src_team?.nickname}</span>
                </span>
              </div>
            )}
            {rawData.contract_info?.src_contract_end && (
              <div className={styles.formGroup}>
                <label>{t('athlete_list.box6')}:</label>
                <span>{moment(rawData.contract_info?.src_contract_end).format('DD/MM/YYYY')}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className={styles.athleteInfo}>
                <div className={styles.generalData}>
                    <div className={styles.leftContent}>
                        <div className={styles.athleteName}>
                            <span><Input rounded name="nickname" value={modifiedAthlete.nickname} onChange={(e) => handleInputChange(e)} /></span>
                            <span><Input rounded name="fullname" value={modifiedAthlete.fullname} onChange={(e) => handleInputChange(e)} /></span>
                        </div>
                        <div className={styles.athleteAge}>
                            <div className={styles.formGroup}>
                                <label>Posição:</label>
                                <span><Input rounded name="position" value={modifiedAthlete.position} onChange={(e) => handleInputChange(e)} /></span>
                                <label>Idade:</label>
                                <span>
                                    '{rawData.birthyear % 100} ({rawData.age} anos)
                                </span>
                                {nationalities}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.aditionalData}>
                    <div className={styles.birthDiv}>
                        <div className={styles.formGroup}>
                            <label>Nascimento:</label>
                            <Calendar name="birthdate" value={new Date(modifiedAthlete.birthdate)} setValue={(e) => handleCalendarChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Idade:</label>
                            <span>{rawData.age} anos</span>
                        </div>
                        <div className={styles.formGroup}>
                            <label>Altura:</label>
                            <Input rounded name="height" value={(modifiedAthlete.height && modifiedAthlete.height + ' cm') || '-'} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Peso:</label>
                            <Input rounded name="weight" value={(modifiedAthlete.weight && modifiedAthlete.weight + ' kg') || '-'} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>
                    <div className={styles.positionDiv}>
                        <div className={styles.formGroup}>
                            <label>Posição:</label>
                            <Input rounded name="position" value={modifiedAthlete.position} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Pé preferencial:</label>
                            <Input rounded name="foot" value={modifiedAthlete.foot} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Gols registrados:</label>
                            <Input rounded name="totalGoals" value={modifiedAthlete.totalGoals} onChange={(e) => handleInputChange(e)} />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Jogos registrados:</label>
                            <Input rounded name="totalMatches" value={modifiedAthlete.totalMatches} onChange={(e) => handleInputChange(e)} />
                        </div>
                    </div>
                    <div className={styles.clubInfo}>
                        {rawData.contract_info?.current_team && (
                            <div className={styles.formGroup}>
                                <label>Clube atual:</label>
                                <span className={styles.flags}>
                                    {rawData.contract_info?.current_team && (
                                        <img
                                            src={rawData.contract_info?.current_team?.country?.flag}
                                            alt={`Bandeira do ${rawData.contract_info?.current_team?.country?.name}`}
                                        />
                                    )}
                                    {rawData.contract_info?.current_team && (
                                        <img
                                            src={rawData.contract_info?.current_team?.emblem}
                                            alt={`Bandeira do ${rawData.contract_info?.current_team?.nickname}`}
                                        />
                                    )}
                                    <span>{rawData.contract_info?.current_team?.nickname}</span>
                                </span>
                            </div>
                        )}
                        {rawData.contract_info?.current_contract_end && (
                            <div className={styles.formGroup}>
                                <label>Fim do contrato:</label>
                                <span>
                                    {rawData.contract_info?.current_contract_end &&
                                        moment(rawData.contract_info?.current_contract_end).format(
                                            'DD/MM/YYYY'
                                        )}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={styles.clubInfo}>
                        {rawData.contract_info?.src_team && (
                            <div className={styles.formGroup}>
                                <label>Clube cedente:</label>
                                <span className={styles.flags}>
                                    <img
                                        src={rawData.contract_info?.src_team?.country?.flag}
                                        alt={`Bandeira do ${rawData.contract_info?.src_team?.country?.name}`}
                                    />
                                    <img
                                        src={rawData.contract_info?.src_team?.emblem}
                                        alt={`Bandeira do ${rawData.contract_info?.src_team?.country?.nickname}`}
                                    />
                                    <span>{rawData.contract_info?.src_team?.nickname}</span>
                                </span>
                            </div>
                        )}
                        {rawData.contract_info?.src_contract_end && (
                            <div className={styles.formGroup}>
                                <label>Fim do contrato:</label>
                                <span>
                                    {moment(rawData.contract_info?.src_contract_end).format('DD/MM/YYYY')}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
    </Card>
  );

  const [fieldsEvaluation, setFieldsEvaluation] = useState([]);
  const setFieldsEvaluationCb = useCallback(setFieldsEvaluation, []);
  const evaluationsOptions = evaluationsData.map(e => {
    return {
      label: `${moment(e.evaluation_date).format('DD/MM/YYYY')} - (${t('rest.box5')} ${(
        e.general_evaluation ?? 0
      ).toFixed(1)}) ${e.author?.name ?? ''}`,
      value: `${e.id}`,
    };
  });

  const athleteEvaluations = modifiedAthlete.evaluationId
    ? evaluationsData.find(e => e.id === modifiedAthlete.evaluationId)
    : undefined;
  useEffect(() => {
    if (configuration.evaluations && athleteEvaluations && fieldsEvaluation.length === 0) {
      getAthleteEvaluation(modifiedAthlete.id, athleteEvaluations.id).then(data => {
        if (data && data.error === undefined && data.data) {
          let thisModifiedAthlete = modifiedAthlete;
          if (
            modifiedAthlete.opinion.length === 0 ||
            modifiedAthlete.background.length === 0 ||
            modifiedAthlete.microaspects.length
          ) {
            thisModifiedAthlete = { ...modifiedAthlete };
            if (thisModifiedAthlete.opinion.length === 0) {
              thisModifiedAthlete.opinion = data.data.feedback;
            }
            if (thisModifiedAthlete.background.length === 0) {
              thisModifiedAthlete.background = data.data.teamContext;
            }
            if (thisModifiedAthlete.microaspects.length === 0 && data.data.values?.fields) {
              thisModifiedAthlete.microaspects = data.data.values.fields.map(fe => fe.feedback);
            }
            setModifiedAthlete(thisModifiedAthlete);
          }
          const dataFields = data?.data?.values?.fields ?? [];
          dataFields.forEach((df, index) => {
            if (index < thisModifiedAthlete.microaspects.length) {
              df.feedback = thisModifiedAthlete.microaspects[index];
            }
          });
          setFieldsEvaluation(dataFields);
        }
      });
    }
  }, [configuration, modifiedAthlete]); // eslint-disable-line react-hooks/exhaustive-deps

  const athleteHistorical = historicalData.map(row => {
    const season = row.season;
    const team = (
      <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} className={styles.teamRow} />
    );
    const competitionName = row.competition_name;
    const minutesPlayed = row.minutes_played;
    const appearances = row.appearances;
    const lineups = row.lineups;
    const subsIn = row.subs_in;
    const subsOut = row.subs_out;
    const goals = row.goals;
    const assists = row.assists;
    const yellowCards = row.yellow_cards;
    const secondYellow = row.second_yellow_cards;
    const redCards = row.red_cards;

    return [
      season,
      team,
      competitionName,
      minutesPlayed,
      appearances,
      lineups,
      subsIn,
      subsOut,
      goals,
      assists,
      yellowCards,
      secondYellow,
      redCards,
    ];
  });

  function athleteHistoricalReducer() {
    if (athleteHistorical.length > 0) {
      let result = athleteHistorical;
      let prevSeason = athleteHistorical[0][0];
      let currentSeason = '';
      let arr = [];

      let minutesPlayed = athleteHistorical[0][3];
      let appearances = athleteHistorical[0][4];
      let lineups = athleteHistorical[0][5];
      let subsIn = athleteHistorical[0][6];
      let subsOut = athleteHistorical[0][7];
      let goals = athleteHistorical[0][8];
      let assists = athleteHistorical[0][9];
      let yellowCards = athleteHistorical[0][10];
      let secondYellow = athleteHistorical[0][11];
      let redCards = athleteHistorical[0][12];

      for (let i = 1; i < athleteHistorical.length; i++) {
        currentSeason = athleteHistorical[i][0];

        if (prevSeason === currentSeason) {
          minutesPlayed += athleteHistorical[i][3];
          appearances += athleteHistorical[i][4];
          lineups += athleteHistorical[i][5];
          subsIn += athleteHistorical[i][6];
          subsOut += athleteHistorical[i][7];
          goals += athleteHistorical[i][8];
          assists += athleteHistorical[i][9];
          yellowCards += athleteHistorical[i][10];
          secondYellow += athleteHistorical[i][11];
          redCards += athleteHistorical[i][12];
        } else {
          arr = [
            '',
            '',
            t('club.box9') + prevSeason,
            minutesPlayed,
            appearances,
            lineups,
            subsIn,
            subsOut,
            goals,
            assists,
            yellowCards,
            secondYellow,
            redCards,
          ];
          result.splice(i, 0, arr);

          prevSeason = currentSeason;
          minutesPlayed = 0;
          appearances = 0;
          lineups = 0;
          subsIn = 0;
          subsOut = 0;
          goals = 0;
          assists = 0;
          yellowCards = 0;
          secondYellow = 0;
          redCards = 0;
        }
      }

      let last = result.length - 1;

      minutesPlayed = athleteHistorical[last][3];
      appearances = athleteHistorical[last][4];
      lineups = athleteHistorical[last][5];
      subsIn = athleteHistorical[last][6];
      subsOut = athleteHistorical[last][7];
      goals = athleteHistorical[last][8];
      assists = athleteHistorical[last][9];
      yellowCards = athleteHistorical[last][10];
      secondYellow = athleteHistorical[last][11];
      redCards = athleteHistorical[last][12];

      arr = [
        '',
        '',
        t('club.box9') + currentSeason,
        minutesPlayed,
        appearances,
        lineups,
        subsIn,
        subsOut,
        goals,
        assists,
        yellowCards,
        secondYellow,
        redCards,
      ];
      result.push(arr);

      return result;
    } else return [];
  }
  const athleteHistoricalReduce = athleteHistoricalReducer();

  let athleteTransfersTotal = 0;
  const athleteTransfers = transfersData.map(row => {
    const season = row.season;
    const date = row.transfer_date && moment(row.transfer_date).format('DD/MM/YYYY');
    const srcTeam = row.src_team && (
      <div className={styles.flagsDiv}>
        {row.src_team?.country?.flag && (
          <img
            className={styles.countryFlag}
            src={row.src_team?.country?.flag}
            alt={row.src_team?.country?.name ?? ''}
          />
        )}
        <TeamRow name={row.src_team?.nickname} id={row.src_team?.id} img={row.src_team?.emblem} />
      </div>
    );
    const dstTeam = row.dst_team && (
      <div className={styles.flagsDiv}>
        <FaLongArrowAltRight />
        {row.dst_team?.country?.flag && (
          <img
            className={styles.countryFlag}
            src={row.dst_team?.country?.flag}
            alt={row.dst_team?.country?.name ?? ''}
          />
        )}
        <TeamRow name={row.dst_team?.nickname} id={row.dst_team?.id} img={row.dst_team?.emblem} />
      </div>
    );
    const age = `${row.age_at_date.years} ${t('athlete_card.idade')}}, ${row.age_at_date.months} ${t(
      'athlete_card.meses'
    )}} ${row.age_at_date.days} ${t('athlete_card.days')}}`;
    const value = row.transfer_value;

    if (row.monetary_value !== null) athleteTransfersTotal = athleteTransfersTotal + row.monetary_value;

    return [season, date, srcTeam, dstTeam, age, value];
  });

  const athleteContracts =
    contractsData &&
    contractsData.map(row => {
      const bidDate = row.contract_info?.bid_date && moment(row.contract_info?.bid_date).format('DD/MM/YYYY');
      const team = <TeamRow name={row.team?.nickname} id={row.team?.id} img={row.team?.emblem} />;
      const state = row.contract_info?.state;
      const type = row.contract_info?.type;

      const contractActive = moment(row.contract_info.end, 'YYYY-MM-DD')._d >= moment();
      const contract = row.contract_info ? (
        <span className={styles.contractStatus}>
          {contractActive ? (
            <div className={`${styles.dotIcon} ${styles.purpleDot}`}></div>
          ) : (
            <div className={styles.dotIcon}></div>
          )}
          {row.contract_info.contract}
        </span>
      ) : (
        ''
      );
      const start = row.contract_info?.start && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.start).format('DD/MM/YYYY')}
        </span>
      );
      const end = row.contract_info?.end && (
        <span className={contractActive ? styles.contractActive : styles.contractInactive}>
          {moment(row.contract_info?.end).format('DD/MM/YYYY')}
        </span>
      );

      return [bidDate, team, state, type, contract, start, end];
    });

  return (
    <>
      <ReportAppBar />
      <div className={styles.allInfo}>
        {athleteData}
        {(configuration.generalFeatures || configuration.negotiation || configuration.images) && (
          <Card softShadow className={styles.athletePerformance}>
            {configuration.generalFeatures && (
              <div className={styles.generalData}>
                <label className={styles.labelTitle}>{t('club.box10')}</label>
                <div className={styles.textWrapper}>
                  {editingField.generalFeatures ? (
                    <TextArea
                      onBlur={() => setEditingField({ ...editingField, generalFeatures: false })}
                      fluid
                      name="generalFeatures"
                      onChange={e => handleInputChange(e)}
                      value={modifiedAthlete.generalFeatures}
                    />
                  ) : (
                    <div>
                      <span>{modifiedAthlete.generalFeatures}</span>
                      <Button
                        noShadow
                        color="link"
                        onClick={() => {
                          setEditingField({ ...editingField, generalFeatures: true });
                        }}
                      >
                        {t('field.edit')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {configuration.negotiation && (
              <div className={styles.generalData} style={{ display: 'block' }}>
                <label className={styles.labelTitle}>Negociação</label>
                <div className={styles.negotiation}>
                  <div className={styles.info}>
                    <span>{t('club.box12')}:</span>
                    <span>
                      <Input
                        rounded
                        name="agency"
                        value={modifiedAthlete.negotiation?.agency ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>{t('contact_card.phone')}:</span>
                    <span>
                      <Input
                        rounded
                        name="agency_phone"
                        value={modifiedAthlete.negotiation?.agency_phone ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency_phone: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>Whatsapp:</span>
                    <span>
                      <Input
                        rounded
                        name="agency_whatsapp"
                        value={modifiedAthlete.negotiation?.agency_whatsapp ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, agency_whatsapp: e.target.value },
                          });
                        }}
                      />
                    </span>
                  </div>
                  <div className={styles.inputs}>
                    <span>{t('club.box14')}:</span>
                    <span>
                      <Input
                        rounded
                        name="investiment"
                        value={modifiedAthlete.negotiation?.investiment ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, investiment: e.target.value },
                          });
                        }}
                      />
                    </span>
                    <span style={{ marginTop: '10px' }}>{t('club.box13')}:</span>
                    <span>
                      <Input
                        rounded
                        name="revenue"
                        value={modifiedAthlete.negotiation?.revenue ?? ''}
                        onChange={e => {
                          e.persist();
                          setModifiedAthlete({
                            ...modifiedAthlete,
                            negotiation: { ...modifiedAthlete.negotiation, revenue: e.target.value },
                          });
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
            {configuration.images && (
              <div className={`${styles.generalData} ${styles.imagesContainer}`}>
                <div>
                  <label className={styles.labelTitle}>{t('rest.box6')}</label>
                  {modifiedAthlete.files.length < 6 && (
                    <>
                      <input
                        type="file"
                        hidden
                        multiple
                        ref={inputFileRef}
                        onChange={handleFileUpload}
                        accept="image/webp,image/png,image/jpeg"
                      />
                      <Button
                        className={styles.buttonAddFile}
                        rounded
                        smaller
                        noShadow
                        color="white"
                        onClick={triggerInputFile}
                      >
                        {t('rest.box7')}
                      </Button>
                    </>
                  )}
                  {modifiedAthlete.files.length > 0 && (
                    <ImageList
                      images={modifiedAthlete.files}
                      setImagesFileInputValue={setImagesFileInputValue}
                      setModifiedAthlete={setModifiedAthlete}
                    />
                  )}
                </div>
              </div>
            )}
          </Card>
        )}
        {configuration.evaluations &&
          (evaluationsData.length > 0 ? (
            <div className={styles.evaluation}>
              <label className={styles.labelTitle}>{t('club.box15')}</label>
              <div className={styles.evaluationtop}>
                <Menu
                  title={t('club.box15')}
                  largeMenu
                  rounded
                  value={modifiedAthlete.evaluationId.toString()}
                  setValue={id => {
                    setFieldsEvaluation([]);
                    setModifiedAthlete({ ...modifiedAthlete, evaluationId: parseInt(id) });
                  }}
                  options={evaluationsOptions}
                  placeholder={t('club.box15')}
                  className={styles.menuevaluation}
                />
                <Checkbox
                  checked={configuration.opinion}
                  onChange={() => {
                    setConfiguration({ ...configuration, opinion: !configuration.opinion });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box8')}
                />
                <Checkbox
                  checked={configuration.background}
                  onChange={() => {
                    setConfiguration({ ...configuration, background: !configuration.background });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box9')}
                />
                <Checkbox
                  checked={configuration.aspects}
                  onChange={() => {
                    setConfiguration({ ...configuration, aspects: !configuration.aspects });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box10')}
                />
                <Checkbox
                  checked={configuration.microaspects}
                  onChange={() => {
                    setConfiguration({ ...configuration, microaspects: !configuration.microaspects });
                  }}
                  className={styles.checkboxevaluation}
                  label={t('rest.box11')}
                />
              </div>
              {(modifiedAthlete.evaluationId ?? 0) === 0 ? (
                <h5>{t('rest.box12')}</h5>
              ) : fieldsEvaluation.length === 0 ? (
                <CenteredLoader />
              ) : (
                <>
                  {configuration.opinion && (
                    <div className={styles.generalData}>
                      <label className={styles.labelTitle}>{t('field.pare')}</label>
                      <div className={styles.textWrapper}>
                        {editingField.opinion ? (
                          <TextArea
                            onBlur={() => setEditingField({ ...editingField, opinion: false })}
                            fluid
                            name="opinion"
                            onChange={e => handleInputChange(e)}
                            value={modifiedAthlete.opinion}
                          />
                        ) : (
                          <div>
                            <span>{modifiedAthlete.opinion}</span>
                            <Button
                              noShadow
                              color="link"
                              onClick={() => {
                                setEditingField({ ...editingField, opinion: true });
                              }}
                            >
                              {t('field.edit')}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {configuration.background && (
                    <div className={styles.generalData}>
                      <label className={styles.labelTitle}>{t('club.box16')}</label>
                      <div className={styles.textWrapper}>
                        {editingField.background ? (
                          <TextArea
                            onBlur={() => setEditingField({ ...editingField, background: false })}
                            fluid
                            name="background"
                            onChange={e => handleInputChange(e)}
                            value={modifiedAthlete.background}
                          />
                        ) : (
                          <div>
                            <span>{modifiedAthlete.background}</span>
                            <Button
                              noShadow
                              color="link"
                              onClick={() => {
                                setEditingField({ ...editingField, background: true });
                              }}
                            >
                              {t('field.edit')}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <FieldList
                    fields={fieldsEvaluation}
                    setFields={setFieldsEvaluationCb}
                    showFieldsFeedback={configuration.microaspects}
                    onEditFinish={() => {
                      setModifiedAthlete({ ...modifiedAthlete, microaspects: fieldsEvaluation.map(fe => fe.feedback) });
                    }}
                  />
                </>
              )}
            </div>
          ) : (
            <div className={styles.evaluation}>{t('club.box17')}</div>
          ))}
        {configuration.historic && (
          <Card softShadow className={[styles.performanceByTeam, styles.tableCard].join(' ')}>
            {athleteHistoricalReduce.length > 0 ? (
              <Table
                flat
                fullhead={<label className={styles.labelTitle}>{t('club.box19')}</label>}
                theads={[
                  t('slider_menu.seas'),
                  t('athlete_list.box2'),
                  t('match.comp'),
                  <img src={IcoTempo} alt="tempo" />,
                  <img src={IcoJogos} alt="jogos" />,
                  <img src={IcoTitular} alt="titular" />,
                  <img src={IcoEntrou} alt="entrou" />,
                  <img src={IcoSaiu} alt="saiu" />,
                  <img src={IcoGols} alt="gols" />,
                  <img src={IcoAssistencias} alt="assistências" />,
                  <img src={IcoAmarelos} alt="amarelos" />,
                  <img src={IcoAmareloVermelho} alt="amarelo vermelho" />,
                  <img src={IcoVermelhos} alt="vermelho" />,
                ]}
                data={athleteHistoricalReduce}
              />
            ) : (
              t('rest.box13')
            )}
          </Card>
        )}
        {configuration.career && (
          <Card softShadow className={[styles.transfers, styles.tableCard].join(' ')}>
            {athleteTransfers.length > 0 ? (
              <div className={styles.athleteTransfers}>
                <Table
                  fullhead={<label className={styles.labelTitle}>{t('club.box20')}</label>}
                  flat
                  theads={[
                    t('slider_menu.seas'),
                    t('table.data'),
                    t('table.origin'),
                    t('table.destiny'),
                    t('table.age_moment'),
                    t('table.price'),
                  ]}
                  data={athleteTransfers}
                  footer={`${t('athlete_card.text7')} ${athleteTransfersTotal} M €`}
                />
              </div>
            ) : (
              t('rest.box14')
            )}
          </Card>
        )}
        {configuration.career && (
          <Card softShadow className={[styles.contracts, styles.tableCard].join(' ')}>
            <div className={styles.athleteContracts}>
              {athleteContracts.length > 0 ? (
                <Table
                  fullhead={<label className={styles.labelTitle}>{t('athlete_card.text1')}</label>}
                  flat
                  theads={[
                    t('athlete_card.text2'),
                    t('athlete_list.box2'),
                    t('athlete_card.text5'),
                    t('contact_card.type'),
                    t('filters.contract'),
                    t('athlete_card.text3'),
                    t('athlete_card.text4'),
                  ]}
                  data={athleteContracts}
                />
              ) : (
                t('rest.box15')
              )}
            </div>
          </Card>
        )}
      </div>
    </>
  );
}

ReportEdition.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  configuration: PropTypes.instanceOf(Object).isRequired,
  setAthletesInfoData: PropTypes.func.isRequired,
  athletesInfoData: PropTypes.array.isRequired,
  athleteInfoData: PropTypes.instanceOf(Object).isRequired,
};
