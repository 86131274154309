import React from 'react';
import PropTypes from 'prop-types';

import { Backdrop, Button } from 'components/template';
import styles from './Dialog.module.scss';
import { IoIosClose } from 'react-icons/io';

export default function Dialog({
  children,
  isOpen,
  onClose,
  mainTitle,
  subtitle,
  btnFloatLeft,
  btnFloatLeftClick,
  btnFloatLeftDisabled,
  btnLeft,
  btnLeftClick,
  btnRight,
  btnRightColor,
  btnRightClick,
  btnRightDisabled,
  className,
  showBackdrop,
}) {
  let dialog = null;

  const containerClasses = [styles.container];
  const headerClasses = [styles.dialogHeader];
  const bodyClasses = [styles.dialogBody];
  const footerClasses = [styles.dialogFooter];
  if (className) {
    if (className.container) containerClasses.push(className.container);
    if (className.header) headerClasses.push(className.header);
    if (className.body) bodyClasses.push(className.body);
    if (className.footer) footerClasses.push(className.footer);
  }

  if (isOpen)
    dialog = (
      <div className={containerClasses.join(' ')}>
        <div className={headerClasses.join(' ')}>
          {mainTitle && (
            <div className={styles.title}>
              <h3>{mainTitle}</h3>
              <span>{subtitle}</span>
            </div>
          )}
          <button className={styles.dialogButton} onClick={onClose}>
            <IoIosClose />
          </button>
        </div>
        <div className={bodyClasses.join(' ')}>{children}</div>
        {(btnLeft != null || btnRight != null || btnFloatLeft != null) && (
          <div className={footerClasses.join(' ')}>
            {btnFloatLeft ? (
              <Button
                rounded
                smaller
                color="danger"
                noShadow
                disabled={btnFloatLeftDisabled}
                onClick={btnFloatLeftClick}
              >
                {btnFloatLeft}
              </Button>
            ) : (
              <div></div>
            )}
            <div className={styles.footerButtons}>
              {btnLeft && (
                <Button rounded smaller color="white" noShadow onClick={btnLeftClick}>
                  {btnLeft}
                </Button>
              )}
              {btnRight && (
                <Button
                  rounded
                  smaller
                  color={btnRightColor ?? 'green'}
                  noShadow
                  disabled={btnRightDisabled}
                  onClick={btnRightClick}
                >
                  {btnRight}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );

  return (
    <>
      <Backdrop style={{ top: '0' }} onClick={onClose} isVisible={showBackdrop ?? isOpen} />
      {dialog}
    </>
  );
}

Dialog.default = {
  mainTitle: '',
  subtitle: '',
  btnFloatLeft: null,
  btnFloatLeftClick: {},
  btnFloatLeftDisabled: false,
  btnLeft: null,
  btnLeftClick: {},
  btnRight: null,
  btnRightColor: 'green',
  btnRightClick: {},
  btnRightDisabled: false,
  className: {},
};

Dialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mainTitle: PropTypes.node | PropTypes.string,
  subtitle: PropTypes.string,
  btnFloatLeft: PropTypes.string,
  btnFloatLeftClick: PropTypes.func,
  btnFloatLeftDisabled: PropTypes.bool,
  btnLeft: PropTypes.string,
  btnLeftClick: PropTypes.func,
  btnRight: PropTypes.string,
  btnRightColor: PropTypes.string,
  btnRightClick: PropTypes.func,
  btnRightDisabled: PropTypes.bool,
  className: PropTypes.exact({
    container: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    footer: PropTypes.string,
  }),
};
