import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, Button, StarRating } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import styles from './EvaluationsTable.module.scss';
import { useTranslation } from 'react-i18next';
//import "../../translations/i18n"

export default function EvaluationsTable({ evaluations }) {
  const history = useHistory();
  const { t } = useTranslation();

  const data = evaluations?.map(item => {
    const rate = (
      <div className={styles.ratingContainer}>
        <StarRating rate={item.generalEvaluation} size={15} />
      </div>
    );

    return [
      // <AthleteRow name={item?.athlete?.name} id={item?.athlete?.id} img={item?.athlete?.img} />,
      // item?.athlete?.nickname,
      // <TeamRow name={item?.team?.name} id={item?.team?.id} img={item?.team?.emblem} />,
      moment(item.date).format('DD/MM/YYYY'),
      item.author,
      item.team && item.team?.id ? (
        <div className={styles.flagsDiv}>
          <TeamRow name={item.team?.name} id={item.team?.id} img={item.team?.emblem} />
        </div>
      ) : (
        '-'
      ),
      item.mode,
      rate,
      <Button
        evaluationB
        key={item.id}
        onClick={() => history.push(`/atletas/${item?.athlete?.id}/avaliacoes/${item?.id}`)}
        noShadow
        smaller
        rounded
        color="green"
      >
        <span>{t('table.text')}</span>
      </Button>,
    ];
  });

  return (
    <div className={styles.evaluations}>
      {/* <div className={styles.menuList}></div> */}
      {/* <Table theads={['Jogador', 'Apelido', 'Clube', 'Data', 'Analista', 'Avaliação Geral', ' ']} data={data} /> */}
      <Table
        theads={[t('table.data'), t('table.analist'), t('table.clube'), t('table.model'), t('table.general'), ' ']}
        data={data}
        className={styles.evaluationTable}
      />
    </div>
  );
}

EvaluationsTable.propTypes = {
  evaluations: PropTypes.instanceOf(Object).isRequired,
};
